import IC_ADD from "../res/ic-add.png";
import IC_ARROW_DOWN_BLUE from "../res/ic-arrow-down-blue.png";
import IC_ARROW_DOWN from "../res/ic-arrow-down.svg";
import IC_ARROW_LEFT from "../res/ic-arrow-left.svg";
import IC_ARROW_RIGHT from "../res/ic-arrow-right.svg";
import IC_BELL from "../res/ic-bell.png";
import IC_BUYER_BLUE from "../res/ic-buyer-blue.png";
import IC_BUYER_WHITE from "../res/ic-buyer-white.png";
import IC_CAR_BLUE from "../res/ic-car-blue.png";
import IC_CAR_WHITE from "../res/ic-car-white.png";
import IC_DASHBOARD_BLUE from "../res/ic-dashboard-blue.png";
import IC_DASHBOARD_WHITE from "../res/ic-dashboard-white.png";
import IC_DELETE from "../res/ic-delete.png";
import IC_EDIT_PEN from "../res/ic-edit-pen.svg";
import IC_MASTER_MANAGEMENT_USER from "../res/user-list.svg";
import IC_EXCEL from "../res/ic-excel.png";
import IC_EDIT from "../res/edit.svg";
import IC_EYE_OPEN_FILLED from "../res/eye_open_filled.svg";
import IC_LOGOUT from "../res/ic-logout.png";
import IC_SEARCH from "../res/ic-search.png";
import IC_DEALER_PREFRENCE from "../res/Dealer_preference.png";
import IC_SELLER_BLUE from "../res/ic-seller-blue.png";
import IC_SELLER_WHITE from "../res/ic-seller-white.png";
import IMG_BRAND_LOGO from "../res/img-brand-logo.png";
import IMG_PROFILE_PIC from "../res/img-profile-pic.png";
import IC_INCREASE from "../res/ic-increase.png";
import IC_DECREASE from "../res/ic-decrease.png";
import IC_TOTAL_VISITOR from "../res/ic-total-visitor.png";
import IC_TOTAL_BUYER from "../res/ic-total-buyer.png";
import IC_TOTAL_SELLER from "../res/ic-total-seller.png";
import IC_TOTAL_PENDING from "../res/ic-total-pending.png";
import IC_ADD_WHITE from "../res/ic-add-white.png";
import IC_BACK_ARROW from "../res/ic-back-arrow.png";
import IC_EDIT_PEN_WHITE from "../res/ic-edit-pen-white.png";
import IC_TRANSMISSION from "../res/ic-transmission.png";
import IC_FUEL from "../res/ic-fuel.png";
import IC_ODOMETER from "../res/ic-odometer.png";
import IC_UPLOAD from "../res/upload.png";
import IMG_CAR1 from "../res/car1.png";
import IMG_CAR2 from "../res/car2.png";
import IMG_BACK_BUTTON from "../res/img-back-button.png";
import IMG_FORWARD_BUTTON from "../res/img-forward-button.png";
import IMG_NOCARFOUND from "../res/no-Car-image.webp";
import IMG_MOTORPEDIA_LOGO from "../res/tmp_logo_small.png";
import IMG_MP_FAV from "../image/favicon.ico";

export const R = {
  img_mp_logo: IMG_MOTORPEDIA_LOGO,
  img_mp_fav: IMG_MP_FAV,
  ic_add: IC_ADD,
  ic_arrow_down_blue: IC_ARROW_DOWN_BLUE,
  ic_arrow_down: IC_ARROW_DOWN,
  ic_edit: IC_EDIT,
  ic_arrow_left: IC_ARROW_LEFT,
  ic_arrow_right: IC_ARROW_RIGHT,
  ic_bell: IC_BELL,
  ic_buyer_blue: IC_BUYER_BLUE,
  ic_buyer_white: IC_BUYER_WHITE,
  ic_car_blue: IC_CAR_BLUE,
  ic_car_white: IC_CAR_WHITE,
  ic_dashboard_blue: IC_DASHBOARD_BLUE,
  ic_dashboard_white: IC_DASHBOARD_WHITE,
  ic_user_list: IC_MASTER_MANAGEMENT_USER,
  ic_delete: IC_DELETE,
  ic_edit_pen: IC_EDIT_PEN,
  ic_upload: IC_UPLOAD,
  ic_excel: IC_EXCEL,
  ic_logout: IC_LOGOUT,
  ic_search: IC_SEARCH,
  ic_seller_blue: IC_SELLER_BLUE,
  ic_seller_white: IC_SELLER_WHITE,
  img_brand_logo: IMG_BRAND_LOGO,
  img_profile_pic: IMG_PROFILE_PIC,
  ic_increase: IC_INCREASE,
  ic_eye_open_filled: IC_EYE_OPEN_FILLED,
  ic_decrease: IC_DECREASE,
  ic_total_visitor: IC_TOTAL_VISITOR,
  ic_total_buyer: IC_TOTAL_BUYER,
  ic_total_seller: IC_TOTAL_SELLER,
  ic_total_pending: IC_TOTAL_PENDING,
  ic_add_white: IC_ADD_WHITE,
  ic_back_arrow: IC_BACK_ARROW,
  ic_edit_pen_white: IC_EDIT_PEN_WHITE,
  ic_transmission: IC_TRANSMISSION,
  ic_fuel: IC_FUEL,
  ic_odometer: IC_ODOMETER,
  img_car1: IMG_CAR1,
  img_car2: IMG_CAR2,
  img_back_button: IMG_BACK_BUTTON,
  img_forward_button: IMG_FORWARD_BUTTON,
  ic_preference_logo: IC_DEALER_PREFRENCE,
  img_nocarfound: IMG_NOCARFOUND,
};

export const dealerId = localStorage.getItem("dealerId");
