import { combineReducers } from "redux";
import auth from "./auth/authReducer";
import buyerLead from "./buyerLead/buyerLeadReducer";
import sellerLead from "./sellerLead/sellerLeadReducer";
import car from "./car/carReducer";
import rtoCasesReducer from "./rto-services/rtoCases/rtoCasesReducer";

import inquireLeadReducers from "./inquiryLead/inquireLeadReducers";
export default combineReducers({
  auth,
  buyerLead,
  sellerLead,
  car,
  rtoCasesReducer,
  inquireLeadReducers,
});
