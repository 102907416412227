// import style from "./style.module.scss";
// import { Form, Formik } from "formik";
// import { InputField } from "../../../components/InputField";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import { Button } from "../../../components/buttons";
// import { rtoCasesReqApi } from "../../../api/rtoCasesApi";
// import { useEffect, useState } from "react";
// // import { isAllowed } from "../../../util/Permissions.ts";
// import { R } from "../../../constants/resources.js";
// import BackButton from "../../../components/buttons/BackButton.jsx";
// // import { MAIN_ROUTES, getAbsolute } from "../../../constants/routes.js";

// const ViewRTOCases = () => {
//   const { state } = useLocation();
//   const navigate = useNavigate();
//   // useEffect(() => {
//   //   if (!isAllowed("RTO Cases", "View")) navigate(-1);
//   // }, []);

//   const [codeList, setCodeList] = useState([]);
//   const getCodeList = async () => {
//     const res = await rtoCasesReqApi.getRtoCodeList();
//     setCodeList(res?.data);
//   };

//   useEffect(() => {
//     getCodeList();
//   }, []);

//   return (
//     <>
//       <div
//         style={{
//           marginTop: "20px",
//         }}
//         className={style["header-row"]}
//       >
//         <BackButton onClick={() => navigate("/rto-management")} />
//         <p>{state?.rc_no} - RTO Case Details</p>
//         {state?.status_name?.name != "Completed" &&
//           state?.status_name?.name !== "Cancelled" && (
//             <button
//               className={style["reset-button"]}
//               onClick={() => navigate("/rto-management-edit", { state })}
//             >
//               Edit
//             </button>
//           )}
//       </div>
//       <div className={style["container"]}>
//         <Formik
//           enableReinitialize
//           initialValues={{}}
//           validationSchema={{}}
//           onSubmit={() => {}}
//         >
//           {({}) => (
//             <Form className={style["form-container"]}>
//               <div className={style["wrapper"]}>
//                 <div className={style["input-wrapper"]}>
//                   <InputField
//                     type={""}
//                     label={"Dealership"}
//                     hint={"Enter Dealership"}
//                     value={state?.dealership_name}
//                     disabled
//                   />
//                 </div>
//                 <div className={style["input-wrapper"]}>
//                   <InputField
//                     type={""}
//                     label={"Dealer Name"}
//                     hint={"Enter Dealer Name"}
//                     value={state?.dealership_name}
//                     disabled
//                   />
//                 </div>
//                 <div className={style["input-wrapper"]}>
//                   <InputField
//                     type={""}
//                     label={"Vehicle No"}
//                     hint={"Enter Vehicle Number"}
//                     value={state?.rc_no}
//                     disabled
//                   />
//                 </div>
//                 <div className={style["input-wrapper"]}>
//                   <InputField
//                     type={""}
//                     label={"Authority From"}
//                     hint={"Enter Authority"}
//                     value={
//                       codeList?.find((ele) => ele.id == state?.auth_from)?.regNo
//                     }
//                     disabled
//                   />
//                 </div>
//                 <div className={style["input-wrapper"]}>
//                   <InputField
//                     type={""}
//                     label={"Authority To"}
//                     hint={"Enter Authority"}
//                     value={
//                       codeList?.find((ele) => ele.id == state?.auth_to)?.regNo
//                     }
//                     disabled
//                   />
//                 </div>
//                 <div className={style["input-wrapper"]}>
//                   <InputField
//                     type={""}
//                     label={"Buyer Name"}
//                     hint={"Enter Buyer Name"}
//                     value={state?.buyer_name}
//                     disabled
//                   />
//                 </div>
//                 <div className={style["input-wrapper"]}>
//                   <InputField
//                     type={""}
//                     label={"Buyer Mobile"}
//                     hint={"Enter Mobile Number"}
//                     value={state?.buyer_phone}
//                     disabled
//                   />
//                 </div>
//                 <div className={style["input-wrapper"]}>
//                   <InputField
//                     type={""}
//                     label={"Seller Name"}
//                     hint={"Enter Seller Name"}
//                     value={state?.seller_name}
//                     disabled
//                   />
//                 </div>
//                 <div className={style["input-wrapper"]}>
//                   <InputField
//                     type={""}
//                     label={"Seller Number"}
//                     hint={"Enter Seller Number"}
//                     value={state?.seller_phone}
//                     disabled
//                   />
//                 </div>
//                 <div className={style["input-wrapper"]}>
//                   <InputField
//                     type={""}
//                     label={"Task"}
//                     hint={"Select Tasks"}
//                     value={state?.task_name?.name}
//                     disabled
//                   />
//                 </div>
//                 <div className={style["input-wrapper"]}>
//                   <InputField
//                     type={""}
//                     label={"Additional Task"}
//                     hint={"Select Additional Tasks"}
//                     value={state?.add_task_name?.name}
//                     disabled
//                   />
//                 </div>
//                 <div className={style["input-wrapper"]}>
//                   <InputField
//                     type={""}
//                     label={"Admin Assigned"}
//                     hint={"Admin Assigned"}
//                     value={state?.admin_assigned_name}
//                     disabled
//                   />
//                 </div>
//                 <div className={style["input-wrapper"]}>
//                   <InputField
//                     type={""}
//                     label={"Remarks"}
//                     hint={"Enter Remarks"}
//                     value={state?.remarks}
//                     disabled
//                   />
//                 </div>
//                 <div className={style["input-wrapper"]}>
//                   <InputField
//                     type={""}
//                     label={"Admin Assigned"}
//                     hint={"Admin Assigned"}
//                     value={state?.admin_assigned_name}
//                     disabled
//                   />
//                 </div>
//               </div>
//             </Form>
//           )}
//         </Formik>
//       </div>
//     </>
//   );
// };

// export default ViewRTOCases;



import axios from "axios";
import {
  isImage,
  get_url_extension,
  generateServicePrice,
} from "../../../utils/General.js";
import { Form, Formik } from "formik";
import debounce from "lodash/debounce";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Regex } from "../../../constants/regex";
import { R } from "../../../constants/resources";
import { useCallback, useEffect, useState } from "react";
import { dealerApi } from "../../../api/dealerApi";
import { Button } from "../../../components/buttons";
import { Loading } from "../../../components/Loading";
import { rtoCasesReqApi } from "../../../api/rtoCasesApi";
import { InputField } from "../../../components/InputField";
import validationSchema from "../../../constants/validations";
import { showToast } from "../../../components/toaster/toaster.js";
import style from "./style.module.scss";

import {
  addRtoCases,
  editRtoCases,
} from "../../../store/rto-services/rtoCases/rtoCasesAction";
import { VahanCheckRequestApi } from "../../../api/vahanCheckApi.js";
import { fontSize, padding } from "@mui/system";
import Select from "react-select";
import BackButton from "../../../components/buttons/BackButton.jsx";
import PrimaryHeading from "../../../components/primary-heading/PrimaryHeading.jsx";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, TextField } from "@mui/material";


const ViewRTOCases = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [codeList, setCodeList] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [dealerCoins, setDealerCoins] = useState({});
  const { admin } = useSelector((state) => state.auth);
  const [codeListAuthTo, setCodeListAuthTo] = useState([]);
  const [additionalTaskList, setAdditionalTaskList] = useState([]);
  const [taskValue, setTaskValue] = useState([]);
  const [rtoPrice, setRtoPrice] = useState(state?.rto_amount);
  const [dealers, setDealers] = useState([]);
  const [agents, setAgents] = useState([]); // To store dealers from API
  const [isDealerModalOpen, setDealerModalOpen] = useState(false);
  const [isAgentModalOpen, setAgentModalOpen] = useState(false); // Modal state
  const [newDealerName, setNewDealerName] = useState("");
  const [newAgentName, setNewAgentName] = useState("");

  const [selectedDealer, setSelectedDealer] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(null);

  const [rtoAdditionalCharge, setRtoAdditionalCharge] = useState(0);
  const [rtoDiscountAmount, setRtoDiscountAmount] = useState(0);
  const [agreedAmount, setAgreedAmount] = useState(state?.agreed_amount);
  const [groupToRtoId, setGroupToRtoId] = useState(0);
  const [groupFromRtoId, setGroupFromRtoId] = useState(0);
  const [fromFilteredRecord, setFromFilteredRecord] = useState("");
  const [filteredCodeListAuthTo, setFilteredCodeListAuthTo] = useState([]);
  const [rtoTaskPrice, settaskRtoPrice] = useState(0);
  const [stateList, setStateList] = useState(0);
  const [cityList, setCityList] = useState([]);
  const [regNo, setRegNo] = useState({});
  const [rtpApiRes, setRtpApiRes] = useState(false);
  const [suggestedTask, setSuggestedTask] = useState({});
  const [checkRtoCode, setCheckRtoCode] = useState(0);
  const [cityId, setCityId] = useState(0);
  const [dropCityList, setDropCityList] = useState([]);
  const [fetchBtnClick, setFetchBtnClick] = useState(false);
  const [rtoCode, setRtoCode] = useState(null);
  const [statusList, setStatusList] = useState({});
  const [dealershipName, setDealershipName] = useState(
    state.dealership_name || ""
  );
  const [dealerName, setDealerName] = useState(state.dealer_name || "");

  const [fromRtoCityList, setFromRtoCityList] = useState([]);

  const [additionalTaskAmount, setAdditionalTaskAmount] = useState(0);
  const [amountAgent, setAmountAgent] = useState(0);
  const [recivelAmount, setRecivealAmount] = useState(0);
  const [feeAmount, setFeeAmount] = useState(0);
  const [finalAmont, setFinalAmount] = useState(0);


  const dealer_id = localStorage.getItem("dealerId");
  console.log("dealer_id", dealer_id);

  useEffect(() => {
    setFeeAmount(state?.amount);
    setRecivealAmount(state?.receivable_amount_dealer);
    setAmountAgent(state?.payable_amount_agent);
  }, [setFeeAmount, setRecivealAmount, setAmountAgent])


  const initialValues = {
    dealer_id: state?.dealer_id || "",
    rc_no: state?.rc_no || "",
    auth_from: state?.auth_from || "",
    auth_to: state?.auth_to || "",
    buyer_name: state?.buyer_name || "",
    buyer_phone: state?.buyer_phone || "",
    seller_name: state?.seller_name || "",
    seller_phone: state?.seller_phone || "",
    task_id: state?.task_id || "",
    add_task_id: Array.isArray(state?.add_task_id)
      ? state.add_task_id
      : state?.add_task_id?.split(",") || [], // Ensure array
    remarks: state?.remarks || "",
    amount: state?.amount || "",
    gst_type: "GST_Included",
    use_coins: true,
    amount: state?.amount || "",
    payable_amount_agent: state?.payable_amount_agent || "",
    hpt_type: state?.hpt_type || "",
    noc_type: state?.noc_type || "",
    hpa_type: state?.hpa_type || "",
    noc_only_type: state?.noc_only_type || "no",
    rto_task_amount: state?.rto_task_amount || 0,
    rto_additional_task_amount: state?.rto_additional_task_amount || 0,
    receivable_status: state?.receivable_status || "",
    payable_status: state?.payable_status || "",
    contact_agent_id: state?.contact_agent_id || "",
    contact_dealer_id: state?.contact_dealer_id || "",
    profit_amount: state?.profit_amount || "",
    receivable_amount_dealer: state?.receivable_amount_dealer || "",
    manage_by: state?.manage_by || "",
    state_id: state?.state_id || "",
  };

  console.log("Task-id-is ===>", initialValues?.task_id);


  const resetValues = {
    rc_no: "",
    dealer_id: "",
    auth_from: "",
    auth_to: "",
    chassis_no: "",
    buyer_name: "",
    buyer_phone: "",
    seller_name: "",
    seller_phone: "",
    task_id: "",
    add_task_id: [],
    status: "",
    remarks: "",
    admin_assigned: "",
    amount: null,
    agreed_amount: 0,
    gst_amount: 0,
    consultancy: 0,
    base_consultancy: 0,
    total_amount: 0,
    gst_type: "",
    use_coins: true,
    rto_document: "",
    rto_amount: 0,
    additional_charge: 0,
    discount_amount: 0,
    hpa_type: "",
    hpt_type: "",
    noc_type: "",
    noc_only_type: "no",
    state_id: "",
    city_id: 0,
    rto_task_amount: 0,
    rto_additional_task_amount: 0,
  };

  const getTaskList = async () => {
    const res = await rtoCasesReqApi.getRtoTaskList();
    setTaskList(res?.data);
  };

  const getAdditionalRTOTask = async () => {
    const res = await rtoCasesReqApi.getRtoAdditionalTaskList();
    setAdditionalTaskList(res?.data);
  };

  const getStateList = async () => {
    const res = await rtoCasesReqApi.getStateList();
    // setDropCityList(res?.data[0]?.rto_code);
    setStateList(res);
  };

  const getStatusList = async () => {
    const res = await rtoCasesReqApi.getRtoStatusFilter();
    setStatusList(res?.data || []);
  };
  const getAuthToGroupIdEdit = async (auth_to) => {
    // const res = await rtoCasesReqApi.getRtoCodeList(state?.state_id);
    const res = cityList?.data;
    console.log(res, "====================");
    const item = res?.data?.find((element) => element.id === auth_to);

    setGroupToRtoId(item?.rto_group_id);
  };


  const getAuthFromGroupIdEdit = async (auth_from) => {
    const res = await rtoCasesReqApi.getRtoCodeList(
      (state?.rc_no).substring(0, 2)
    );
    const item = res.data.find((element) => element.id === auth_from);
    setGroupFromRtoId(item?.rto_group_id);
  };

  const fetchCityList = async () => {
    try {
      const response = await rtoCasesReqApi.getCityWiseList({
        city_id: cityId,
      });
      setDropCityList(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (cityId) {
      fetchCityList();
    }
    getFromRtoCodeList();
  }, [cityId]);



  const getFromRtoCodeList = async () => {
    const res = await rtoCasesReqApi.getFromRtoCode();
    setFromRtoCityList(res?.data);
  };

  const getCodeList = async (reqData, regNo) => {
    const res = await rtoCasesReqApi.getRtoCodeList(reqData, "yes");
    // let authToData = await extractRTOCode(regNo);
    // const filteredRecord = (res?.data).filter(
    //   (item) => item.regNo === authToData
    // )[0]?.id;

    // Set state if needed
    // setFromFilteredRecord(filteredRecord);
    setCodeList(res?.data);
    // setCodeListAuthTo(res?.data);

    // Return the updated data
    return res?.data; // Returning the fetched code list
  };

  const getCodeListAuthTo = async (reqData) => {
    if (reqData) {
      const res = await rtoCasesReqApi.getRtoCodeList(reqData);
      // setCodeList(res?.data);
      setCodeListAuthTo(res?.data);
      return res?.data;
    } else {
      return [];
    }
  };

  // Get the filtered array

  const getCityByCodeListAuthTo = async (cityList, reqData) => {
    if (reqData) {
      const filteredCityRecord =
        cityList && cityList.length > 0
          ? cityList?.filter((item) => +item.city_id === +reqData)[0]
            ?.rto_codes_mapping
          : "";
      // let abc = JSON.parse(filteredCityRecord);
      let matchingRecords = "";
      if (JSON.parse(filteredCityRecord)?.length == 1) {
        matchingRecords = codeListAuthTo.filter(
          (record) => JSON.parse(filteredCityRecord)[0] == record.regNo
        );
      } else {
        matchingRecords = codeListAuthTo.filter((record) =>
          filteredCityRecord?.includes(record.regNo)
        );
      }

      matchingRecords
        ? setFilteredCodeListAuthTo(matchingRecords)
        : setCodeListAuthTo(codeListAuthTo);
      // setFilteredCodeListAuthTo(matchingRecords);
      // matchingRecords && matchingRecords.length ?  : setCodeListAuthTo(codeListAuthTo);
      // matchingRecords ? setFilteredCodeListAuthTo(matchingRecords) : '';
    }
  };

  const getCityList = async (reqData) => {
    if (reqData) {
      const res = await rtoCasesReqApi.getCityList(reqData);
      //console.log(res,"########################");
      setCityList(res);
      //setCodeListAuthTo(res?.data);
      return res;
    } else {
      return [];
    }
  };

  // useEffect(() => {
  //   dispatch(
  //     getDealerList({
  //       pagesize: 10,
  //     })
  //   );
  //   dispatch(getUserDropdown({ pageSize: 100 }));
  //   getTaskList();
  //   getAdditionalRTOTask();
  //   // getCodeList();
  //   getStateList();
  //   getStatusList();
  //   /** this function is used for task auto suggestion */
  //   // autoTaskSuggestion();
  //   /** End */
  // }, []);

  useEffect(() => {
    getTaskList();
    getAdditionalRTOTask();
    getCodeList((state?.rc_no).substring(0, 2));
    getStatusList();
    getDealerCoins();
    getStateList((state?.rc_no).substring(0, 2));
    getCodeListAuthTo(state?.state_id);
    setCityId(state?.city_id);
    setRegNo(state?.rc_no);
    getCityList(state?.state_id);
    getStateList();
    // getAuthToGroupId(state);
    // getAuthFromGroupId(state)

    getAuthToGroupIdEdit(state?.auth_to);
    getAuthFromGroupIdEdit(state?.auth_from);
  }, []);
  const token = localStorage.getItem("accessToken");
  const handleUpload = async (e, setFieldValue) => {
    setUploading(true);
    try {
      if (e.target.files) {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("container", "rto-service");
        formData.append("id", admin?.data?.id);
        const res = await axios.post(
          `${process.env.REACT_APP_SERVICE_HISTORY_URL}uploadFiles`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (res?.data?.status) {
          setFieldValue("rto_document", res?.data?.fileUploadedLinks[0]);
          setUploading(false);
        }
      }
    } catch (err) {
      setUploading(false);
      console.error(err);
    } finally {
      setUploading(false);
    }
  };

  const getVehicleStatusList = (rc_no, statusList) => {
    const stateCode = rc_no?.slice(0, 2);
    let tempVehicleArray = [];
    if (Object.keys(statusList).length === 0) return [];
    if (
      stateCode &&
      statusList?.hasOwnProperty(stateCode) &&
      statusList[stateCode]
    ) {
      tempVehicleArray = statusList[stateCode]?.map((status) => ({
        label: status.name,
        value: status.id,
      }));
    }
    tempVehicleArray = [
      ...tempVehicleArray,
      ...statusList["null"]?.map((status) => ({
        label: status.name,
        value: status.id,
      })),
    ];
    return tempVehicleArray;
  };

  const handleServiceAmount = (values, setFieldValue) => {
    const { consultancy, base_consultancy, gst_amount, total_amount } =
      generateServicePrice({
        agreed_amount: agreedAmount || 0,
        amount: values?.amount || 0,
        isGst: values?.gst_type || "",
      });
    // console.log(consultancy,'==========',base_consultancy,'==========',gst_amount,'==========',total_amount);
    setFieldValue("consultancy", consultancy?.toFixed(2) * 1 || 0);
    setFieldValue("base_consultancy", base_consultancy?.toFixed(2) * 1 || 0);
    setFieldValue("gst_amount", gst_amount?.toFixed(2) * 1 || 0);
    setFieldValue("total_amount", total_amount?.toFixed(2) * 1 || 0);
    setFieldValue("agreed_amount", agreedAmount);
    setFieldValue("rto_amount", rtoPrice || 0);

    setFieldValue("rto_task_amount", +rtoPrice - +additionalTaskAmount || 0);
    setFieldValue("rto_additional_task_amount", additionalTaskAmount || 0);
  };

  /**
   * RTO PRICE CALCULATION
   */

  // const getRtoAmount = async (task_id, taskType, groupFromRtoinfo = "") => {
  //   let groupFromRtoIdData = groupFromRtoinfo
  //     ? groupFromRtoinfo
  //     : groupFromRtoId;
  //   if (task_id && task_id?.length > 0 && taskType === "additional") {
  //     const res = await rtoCasesReqApi.getRtoPrice({
  //       to_group_id: groupToRtoId,
  //       from_group_id: groupFromRtoIdData,
  //       task_id: taskType === "additional" ? task_id : [task_id],
  //       task_type: taskType,
  //     });
  //     setRtoPrice(+state?.rto_task_amount + +res?.data?.rto_amount || 0);
  //     setAdditionalTaskAmount(res?.data?.rto_amount);
  //     setAgreedAmount(+state?.rto_task_amount + +res?.data?.rto_amount || 0);
  //   } else if (taskType === "additional") {
  //     setRtoPrice(state?.rto_task_amount);
  //     setAgreedAmount(state?.rto_task_amount);
  //     setSuggestedTask({});
  //     setAdditionalTaskAmount(0);
  //   } else {
  //     const res = await rtoCasesReqApi.getRtoPrice({
  //       to_group_id: groupToRtoId,
  //       from_group_id: groupFromRtoIdData,
  //       task_id: taskType === "additional" ? task_id : [task_id],
  //       task_type: taskType,
  //     });
  //     console.log(res.data, "@@@@@@@@@@@@@@");
  //     setRtoPrice(+res?.data?.rto_amount + +state?.rto_additional_task_amount);
  //     settaskRtoPrice(res?.data?.rto_amount);
  //     setAgreedAmount(+res?.data?.rto_amount + +state?.rto_additional_task_amount);
  //     setSuggestedTask({});
  //   }
  // };


  const getAuthToGroupId = async (codeListAuthToData) => {
    const item = codeListAuthTo.find(
      (element) => +element.id === +codeListAuthToData
    );
    setGroupToRtoId(item?.rto_group_id);
  };

  const getAuthFromGroupId = async (codeListAuthToData) => {
    const item = codeList.find(
      (element) => element.id === codeListAuthToData?.auth_from
    );
    setGroupFromRtoId(item?.rto_group_id);
  };

  const handleRtoAmount = (values, setFieldValue) => {
    const { consultancy, base_consultancy, gst_amount, total_amount } =
      generateServicePrice({
        agreed_amount: values?.agreed_amount || 0,
        amount: values?.amount || 0,
        isGst: values?.gst_type || "",
      });
    setFieldValue("consultancy", consultancy?.toFixed(2) * 1 || 0);
    setFieldValue("base_consultancy", base_consultancy?.toFixed(2) * 1 || 0);
    setFieldValue("gst_amount", gst_amount?.toFixed(2) * 1 || 0);
    setFieldValue("total_amount", total_amount?.toFixed(2) * 1 || 0);
  };

  const handleAdditionalAmount = (values, setFieldValue) => {
    const additionalCharge = parseFloat(values.additional_charge) || 0;
    const amount = parseFloat(rtoPrice) || 0;
    const totalAgreedAmount = amount + additionalCharge;
    setAgreedAmount(totalAgreedAmount?.toFixed(2));
    setRtoAdditionalCharge(additionalCharge);
    setFieldValue("agreed_amount", totalAgreedAmount || agreedAmount);
    setFieldValue("rto_amount", amount || 0);

    setFieldValue("rto_task_amount", +rtoPrice - +additionalTaskAmount || 0);
    setFieldValue("rto_additional_task_amount", additionalTaskAmount || 0);

    setFieldValue("consultancy", 0);
    setFieldValue("base_consultancy", 0);
    setFieldValue("gst_amount", 0);
    setFieldValue("total_amount", 0);
    setFieldValue("amount", 0);
  };

  const handleDiscountAmount = (values, setFieldValue) => {
    const discountedAmount = parseFloat(values.discount_amount) || 0;
    const amount = parseFloat(rtoPrice) || 0;
    const additionalCharge = parseFloat(rtoAdditionalCharge) || 0;

    setRtoDiscountAmount(discountedAmount);

    const totalAgreedAmount = amount + additionalCharge - discountedAmount;
    setAgreedAmount(totalAgreedAmount);
    setFieldValue("agreed_amount", totalAgreedAmount || agreedAmount);

    setFieldValue("consultancy", 0);
    setFieldValue("base_consultancy", 0);
    setFieldValue("gst_amount", 0);
    setFieldValue("total_amount", 0);
    setFieldValue("amount", 0);
  };

  /**
   * END
   */

  const editRTORequest = (values, { setSubmitting }) => {
    if (parseInt(values?.agreed_amount) < parseInt(values?.amount)) {
      showToast("error", "Agreed Amount should be greater than fee amount");
      return;
    }

    const formattedValues = {
      ...values,
      add_task_id: Array.isArray(values.add_task_id)
        ? values.add_task_id.join(",")
        : values.add_task_id, // Ensure it's a string
      profit_amount: finalAmont
    };

    setLoading(true);
    const { use_coins, ...rest } = formattedValues;
    dispatch(
      editRtoCases({
        case_id: state?.id,
        updateDetails: {
          ...rest,
        },
        use_coins,
      })
    )
      .then((res) => {
        console.log("response-data ==>", res);
        setSubmitting(false);
        setLoading(false);
        showToast("success", res?.message);
        navigate("/rto-management");
      })
      .catch((err) => {
        console.log("error message ==> ", err.message);

        showToast(
          "error",
          err?.response?.data?.error || err?.response?.data?.message
        );
        setSubmitting(false);
        setLoading(false);
      });
  };

  const [searchDealer, setSearchDealer] = useState("");
  const getDealerCoins = async (dealer_id) => {
    try {
      const res = await dealerApi.getDealerCoins(dealer_id, 2);
      setDealerCoins(res);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    let tempObj = {
      pagesize: 10,
    };
    if (searchDealer) {
      tempObj["searchName"] = searchDealer;
    }
    // dispatch(getDealerList(tempObj));
  }, [searchDealer]);

  const debouncedChangeHandler = useCallback(
    debounce((id, value) => {
      if (id === "dealer_id") {
        setSearchDealer(value);
      }
    }, 700),
    []
  );

  const extractRTOCode = async (vehicleNumber) => {
    try {
      // console.log("Vehicle Number:", vehicleNumber);
      // console.log("SetFieldvalue Function:", setFieldvalue);

      // Extract RTO code using regex
      const match = vehicleNumber.match(/^([A-Z]+\d{1,2})/);

      if (match) {
        return match[1];
      } else {
        return null;
        // console.log("No match found for RTO code.");
        // setFieldvalue("auth_from", null); // Set null if no match
      }
    } catch (error) {
      return null;
      // console.error("Error in extractRTOCode:", error);
      // setFieldvalue("auth_from", null); // Handle errors gracefully
    }
  };

  // VahanCheck
  const viewVahanCheck = async (values, setFieldvalue, refresh) => {
    try {
      if (values == "") {
        showToast("error", "Vehicle No required for fetch rto details.");
        return;
      } else if (values.length < 8) {
        showToast("error", "Vehicle Number should be valid.");
        return;
      }
      let requestBody = {};
      requestBody = { dealer_id: dealer_id, rc_no: values };
      if (refresh) {
        requestBody = {
          dealer_id: dealer_id,
          rc_no: values,
          refresh: true,
        };
      }

      const response =
        await VahanCheckRequestApi.getvehicleDetailsWithoutDealer(
          requestBody
        ).catch((error) => {
          console.error("Something went wrong", error);
          return null;
        });
      if (response?.status) {
        // if (getMakeId) {
        // setMakeId(getMakeId);
        // setFieldvalue("make_id", getMakeId);

        // await getModel(getMakeId);

        // const getModelId = modelNumber?.find(
        //   (ele) =>
        //     ele?.model_name?.toLowerCase() ===
        //     response?.data?.model?.split(" ")[0]?.toLowerCase()
        // )?.id;

        // if (getModelId) {
        // setFieldvalue("model_id", getModelId);
        // }
        // }
        // setFieldvalue("engine_no", response?.data?.engine);

        let hpt = "";
        if (
          response?.data?.rcFinancer == null ||
          response?.data?.rcFinancer == "N/A" ||
          response?.data?.rcFinancer == "NA" ||
          response?.data?.rcFinancer == "On Cash" ||
          response?.data?.rcFinancer == ""
        ) {
          hpt = "no";
        } else {
          hpt = "yes";
        }
        setFieldvalue("hpt_type", hpt);
        const modifiedString = response?.data?.rtoCode.slice(0, 2);
        const updatedCodeList = await getCodeList(
          modifiedString,
          response?.data?.rtoCode,
          "yes"
        );

        const setRtoItem = fromRtoCityList.find(
          (ele) =>
            ele?.rto_office_name?.toLowerCase() ===
            response?.data?.regAuthority?.toLowerCase()
        );

        const setItem = updatedCodeList.find((ele) =>
          setRtoItem
            ? ele?.regNo === setRtoItem?.rto_code
            : ele?.regNo === response?.data?.rtoCode
        );

        setRtoCode(response?.data?.rtoCode || null);
        setFieldvalue("auth_from", setItem?.id);
        setCheckRtoCode(setItem?.id);

        setFieldvalue("chassis_no", response?.data?.chassis);
        setFieldvalue("seller_name", response?.data?.owner);
        setRtpApiRes(true);

        // need to modify with accoding to condiction
        // let stateCode = (response?.data?.rtoCode).slice(0, 2);
        // if(stateCode === 'OD'){
        //   stateCode = "OR";
        // } else if(stateCode === 'TS'){
        //   stateCode = "TG";
        // } else {
        //   stateCode = stateCode;
        // }
        // setFieldvalue("state_id", stateCode);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const autoTaskSuggestion = async (
    requestBody,
    currentClick,
    setFieldValue,
    type
  ) => {
    const item = codeList.find(
      (element) => element.id == requestBody.auth_from
    );
    setGroupFromRtoId(item?.rto_group_id);
    await getAuthFromGroupId({ auth_from: checkRtoCode });
    console.log("checkRtoCode", checkRtoCode);
    console.log(
      "first",
      codeList.find((item) => item.id === requestBody.auth_from)
    );

    let fromMatchingRecord = codeList.find(
      (item) => item.id === requestBody.auth_from
    )?.regNo;
    let toMatchingRecord = codeListAuthTo.find(
      (item) => item.id == requestBody.auth_to
    )?.regNo;
    let reqBody = {
      currentRTO: fromMatchingRecord || "",
      destinationRTO: toMatchingRecord || "",
      noc: type === "noc" ? currentClick : requestBody?.noc_type || "",
      hpa: type === "hpa" ? currentClick : requestBody?.hpa_type || "",
      hpt: type === "hpt" ? currentClick : requestBody?.hpt_type || "",
      noc_only:
        type === "noc_only" ? currentClick : requestBody?.noc_only_type || "",
      state: requestBody?.state_id,
    };
    const response = await rtoCasesReqApi
      .getAutoTaskSuggestion(reqBody)
      .catch((error) => {
        console.error("Something went wrong", error);
        return null;
      });
    if (response?.data.id != 0) {
      // await getRtoAmount(response?.data?.id, "main", item?.rto_group_id);
      setFieldValue("task_id", response?.data?.id);
      setSuggestedTask(response?.data);
    } else {
      setFieldValue("amount", 0);
      setFieldValue("additional_charge", 0);
      setFieldValue("discount_amount", 0);
      setFieldValue("agreed_amount", 0);
    }
  };

  const taskChangeValueReset = (values, setFieldValue) => {
    setFieldValue("consultancy", 0);
    setFieldValue("base_consultancy", 0);
    setFieldValue("gst_amount", 0);
    setFieldValue("total_amount", 0);
    setFieldValue("amount", 0);
    setFieldValue("additional_charge", 0);
    setFieldValue("discount_amount", 0);
    setFieldValue("agreed_amount", 0);
  };

  const resetRtoToChange = (setFieldValue) => {
    setFieldValue("hpa_type", "");
    setFieldValue("noc_type", "");
  };

  const fetchRTODealerList = async () => {
    const agent_id = localStorage.getItem("dealerId");
    const type = "dealer";
    try {
      const res = await dealerApi.getDealersList(agent_id, type);
      setDealers(res);
    } catch (error) {
      console.error("Error fetching dealers:", error);
    }
  };

  useEffect(() => {
    fetchRTODealerList();
  }, []);

  useEffect(() => {
    if (initialValues?.contact_dealer_id) {
      const dealer = dealers.find(
        (res) => res?.id === initialValues.contact_dealer_id
      );
      if (dealer) {
        setSelectedDealer(dealer);
      } else {
        console.log(
          "Dealer not found for ID:",
          initialValues.contact_dealer_id
        );
      }
    }
  }, [initialValues?.contact_dealer_id, dealers]);

  useEffect(() => {
    if (initialValues?.contact_agent_id) {
      const agent = agents.find(
        (res) => res?.id === initialValues.contact_agent_id
      );
      if (agent) {
        setSelectedAgent(agent);
      } else {
        console.log("Dealer not found for ID:", initialValues.contact_agent_id);
      }
    }
  }, [initialValues?.contact_agent_id, agents]);

  const fetchRTOAgentList = async () => {
    const agent_id = localStorage.getItem("dealerId");
    const type = "agent";
    try {
      const res = await dealerApi.getDealersList(agent_id, type);
      console.log("response-data agent ===>", res);
      setAgents(res);
    } catch (error) {
      console.error("Error fetching dealers:", error);
    }
  };

  useEffect(() => {
    fetchRTOAgentList();
  }, []);

  const handleAddNewDealer = async () => {
    try {
      setLoading(true);
      const res = await dealerApi.saveDealerAgent({
        name: newDealerName,
        type: "dealer",
        agent_id: localStorage.getItem("dealerId"),
      });

      if (res?.statusCode === 200 && res.data) {
        setDealers((prev) => [...prev, res.data]);
        setNewDealerName(""); // Reset input
      } else {
        console.error("Error adding user:", res);
      }
      await fetchRTODealerList();
      setDealerModalOpen(false);
    } catch (error) {
      console.error("API Error:", error);
      alert("Failed to add user. Please try again.");
      setDealerModalOpen(false);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleAddNewAgent = async () => {
    if (newAgentName.trim()) {
      try {
        setLoading(true);
        const res = await dealerApi.saveDealerAgent({
          name: newAgentName,
          type: "agent",
          agent_id: localStorage.getItem("dealerId"),
        });

        if (res?.statusCode === 200 && res.data) {
          setAgents((prev) => [...prev, res.data]); // Add new user to the dropdown
          setNewAgentName(""); // Reset input
          setAgentModalOpen(false); // Close modal
        } else {
          console.error("Error adding user:", res);
        }
        await fetchRTOAgentList();
      } catch (error) {
        console.error("API Error:", error);
        alert("Failed to add user. Please try again.");
      } finally {
        setLoading(false); // Stop loading
      }
    } else {
      alert("Name cannot be empty!");
    }
  };

  const getDropdownItems = (
    dropCityList,
    filteredCodeListAuthTo,
    codeListAuthTo
  ) => {
    if (dropCityList?.length > 0 && dropCityList[0]?.rto_codes_mapping) {
      try {
        const parsedData = JSON.parse(dropCityList[0].rto_codes_mapping);
        return parsedData.map((rto) => ({
          label: rto,
          value: rto,
        }));
      } catch (error) {
        console.error("Invalid JSON in rto_codes_mapping:", error);
        return [];
      }
    }
    const sourceList =
      filteredCodeListAuthTo?.length > 0
        ? filteredCodeListAuthTo
        : codeListAuthTo;
    return sourceList.map((ele) => ({
      label: ele?.regNo,
      value: ele?.id,
    }));
  };

  const handleAuthToChange = (e, codeList, setFieldValue) => {
    const repdata = e.target.value;

    const isString = typeof repdata === "string";
    const valueToSet = isString
      ? codeList.find((ele) => ele?.regNo === repdata)?.id
      : repdata;

    if (valueToSet) {
      setFieldValue("auth_to", valueToSet);
    }
  };




  const calculateValue = (
    amountAgent,
    recivelAmount,
    feeAmount,
    setFieldValue
  ) => {
    const AgentAmount = parseInt(amountAgent);
    const recivaeAmount = parseInt(recivelAmount);
    const AmountFee = parseInt(feeAmount);
    const TotalAmount = recivaeAmount - AmountFee - AgentAmount;
    setFinalAmount(TotalAmount);
  };

  useEffect(() => {
    calculateValue(amountAgent, recivelAmount, feeAmount);
  }, [amountAgent, recivelAmount, feeAmount]);

  useEffect(() => {
    setFinalAmount(state?.profit_amount);
  }, [setFinalAmount])



  return (
    <div className={style["container"]}>
      <div className={style["Heading-wrapper"]}>
        <div className={style["header-wrapper"]}>
          <BackButton onClick={() => navigate("/rto-management")} />
          <PrimaryHeading
            title={"RTO Management"}
            fontSize={"1.4rem"}
            fontWeight={600}
          />
        </div>
        <div>
          <button
            className={style["reset-button"]}
            onClick={() => navigate("/rto-management-edit", { state })}
          >
            Edit
          </button>
        </div>
      </div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema.editRtoCases}
        onSubmit={editRTORequest}
      >
        {({
          isSubmitting,
          values,
          handleChange,
          errors,
          setFieldValue,
          touched,
          setTouched,
          handleBlur,
          handleSubmit,
          resetForm,
        }) => {
          return (
            <Form className={style["form-container"]} onSubmit={handleSubmit}>
              <div className={style["wrapper"]}>
                <div className={style["autocomplete-wrapper"]}>
                  <label
                    style={{
                      width: "200px",
                    }}
                  >
                    Dealership
                  </label>
                  <Autocomplete
                    label="Select Dealer"
                    className={style["dropdown-multiple"]}
                    style={{
                      backgroundColor: "white",
                      border: "none",
                    }}
                    disabled={true}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 45,
                      },
                    }} // Adjust this to match your CSS if required
                    value={selectedDealer}
                    onChange={(event, newValue) => {
                      setFieldValue("contact_dealer_id", newValue?.id);
                      if (newValue?.id === "add_new") {
                        setDealerModalOpen(true); // Open modal for new user input
                      } else {
                        setSelectedDealer(newValue);
                      }
                    }}
                    options={[
                      ...dealers,
                      { name: "Add New User", id: "add_new" },
                    ]} // Add 'Add New User' option
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.name
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Select Dealer" />
                    )}
                    renderOption={(props, option) =>
                      option.name === "Add New User" ? (
                        <li
                          {...props}
                          style={{ color: "blue", fontWeight: "bold" }}
                        >
                          + {option.name}
                        </li>
                      ) : (
                        <li {...props}>{option.name}</li>
                      )
                    }
                    isOptionEqualToValue={(option, value) =>
                      typeof option === "string"
                        ? option === value
                        : option.id === value?.id
                    }
                  />
                  <Dialog
                    open={isDealerModalOpen}
                    onClose={() => setDealerModalOpen(false)}
                  >
                    <DialogTitle>Add New User</DialogTitle>
                    <DialogContent>
                      <TextField
                        autoFocus
                        margin="dense"
                        label="User Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={newDealerName}
                        onChange={(e) => setNewDealerName(e.target.value)}
                      />
                    </DialogContent>
                    <DialogActions>
                      <button onClick={() => setDealerModalOpen(false)}>
                        Cancel
                      </button>
                      <button
                        onClick={handleAddNewDealer}
                        variant="contained"
                        color="primary"
                      >
                        Save Dealer
                      </button>
                    </DialogActions>
                  </Dialog>
                </div>

                <div className={style["reset-field"]} style={{ gridRow: "2" }}>
                  <InputField
                    type={""}
                    label={"Vehicle No"}
                    hint={"Enter Vehicle Number"}
                    name="rc_no"
                    value={values.rc_no}
                    onBlur={handleBlur}
                    onChange={(value) => {
                      setFieldValue("rc_no", value.toUpperCase());

                      setRegNo(value);
                    }}
                    disabled={regNo?.length === 10 ? true : false}
                    className={style["inputfield"]}
                    error={touched?.rc_no && errors?.rc_no}
                    required
                  />
                  <div className={style["button-wrapper"]}>
                    <button
                      type="button"
                      onClick={() => {
                        resetForm();
                        setRegNo("");
                        setFromFilteredRecord("");
                        setRtoPrice();
                        setAgreedAmount();
                        setGroupToRtoId();
                        setRtpApiRes(false);
                        setTaskValue([]);
                        setSuggestedTask({});
                        setCodeList([]);
                        setCodeListAuthTo([]);
                        setFieldValue("state_id", "");
                      }}
                      className={style["reset-buttonnew"]}
                    >
                      Reset
                    </button>
                    <button
                      className={style["reset-buttonnew"]}
                      type="button"
                      onClick={() => {
                        setFetchBtnClick(true);
                        viewVahanCheck(regNo, setFieldValue, rtpApiRes);
                      }}
                    >
                      {loading
                        ? "Loading..."
                        : rtpApiRes
                          ? "Refresh"
                          : "Fetch Details"}
                    </button>
                  </div>
                </div>

                {/* State city list */}
                <div className={style["input-wrapper"]}>
                  <InputField
                    type={"select"}
                    label={"Buyer State"}
                    hint={"Select State"}
                    name="state_id"
                    value={values?.state_id}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value) {
                        setFieldValue("state_id", value);
                        getCodeListAuthTo(value);
                        getCityList(value);
                      } else {
                        setFieldValue("state_id", "");
                        setFieldValue("city_id", "");
                        setFieldValue("auth_to", "");
                        getCityList([]);
                        getCodeListAuthTo([]);
                        setCityId(null);
                        getCityByCodeListAuthTo(null, "");
                      }

                    }}
                    required
                    onBlur={handleBlur}
                    error={touched?.state_id && errors?.state_id}
                    items={stateList?.data?.map((ele) => ({
                      label: ele?.state_list_name,
                      value: ele?.rto_code,
                    }))}
                    disabled={true}
                  />
                </div>

                <div className={style["input-wrapper"]}>
                  <InputField
                    type={""}
                    label={"Buyer Name"}
                    hint={"Enter Buyer Name"}
                    name="buyer_name"
                    value={values.buyer_name}
                    onBlur={handleBlur}
                    onChange={(value) => setFieldValue("buyer_name", value)}
                    error={touched?.buyer_name && errors?.buyer_name}
                    required
                    disabled={true}
                  />
                </div>
                <div className={style["input-wrapper"]}>
                  <InputField
                    type={"number"}
                    label={"Buyer Phone No"}
                    hint={"Enter Mobile Number"}
                    name="buyer_phone"
                    value={values.buyer_phone}
                    onBlur={handleBlur}
                    onChange={(value) => setFieldValue("buyer_phone", value)}
                    error={touched?.buyer_phone && errors?.buyer_phone}
                    length={10}
                    required
                    disabled={true}
                  />
                </div>

                {/* <div className={style["input-wrapper"]}>
                  <InputField
                    type={"search-dropdown"}
                    id="auth_from"
                    name="auth_from"
                    onChange={(id) => {
                      setFieldValue("auth_from", id || fromFilteredRecord || "");
                      getAuthFromGroupId(
                        { ...values, auth_from: fromFilteredRecord ? fromFilteredRecord : id },
                        setFieldValue
                      );
                      setFromFilteredRecord("")
                    }}
                    onQueryChange={(value) => {
                      if (value) {
                        const filteredCodes = codeList.filter((ele) =>
                          ele?.regNo.toLowerCase().includes(value.toLowerCase())
                        );
                        setCodeList(filteredCodes);
                      } else {
                        setFromFilteredRecord("")
                        setFieldValue("auth_from", "")
                        setCodeList([])
                        getCodeList();
                      }
                    }}
                    onBlur={() => handleBlur("auth_from")}
                    hint={"Enter Authority"}
                    label={"Authority From"}
                    required
                    items={codeList?.map((ele) => ({
                      label: ele?.regNo,
                      value: ele?.id,
                    }))}
                    // value={values.auth_from}
                    value={fromFilteredRecord || values.auth_from || ""}
                    error={touched?.auth_from && errors?.auth_from}
                    dropdownHeight={"300px"}
                    renderRow={(item) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "10px 15px",
                          gap: "7px",
                          borderBottom: "1px solid #ebebeb",
                        }}
                      >
                        <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                          {item?.label}
                        </p>
                      </div>
                    )}
                  />
                </div> */}
                {/* <div className={style["input-wrapper"]}> */}
                {/* <InputField
                    type={"select"}
                    hint={"select from"}
                    label={"Authority From"}
                    name="auth_from"
                    value={values?.auth_from}
                    onChange={(e) => {
                      setFieldValue("auth_from", e.target.value);
                      // setCheckRtoCode(e.target.value);
                    }}
                    required
                    onBlur={handleBlur}
                    items={codeList?.map((ele) => ({
                      label: ele?.regNo,
                      value: ele?.id,
                    }))}
                    disabled={rtoCode}
                  /> */}
                <div className={style["input-wrapper"]}>
                  <div className={style["additional-wrapper"]}>
                    <label>Authority From</label>
                    <Select
                      className={style["dropdown-multiple"]}
                      value={codeList
                        .filter((ele) => ele.id === values?.auth_from)
                        .map((ele) => ({
                          label: ele?.regNo,
                          value: ele?.id,
                        }))}
                      name="auth_from"
                      options={codeList?.map((ele) => ({
                        label: ele?.regNo,
                        value: ele?.id,
                      }))}
                      onChange={(e) => {
                        setFieldValue("auth_from", e.value);
                        // setCheckRtoCode(e.target.value);
                      }}
                      isDisabled={true}
                      onBlur={handleBlur}
                    />
                    {touched.auth_from && errors.auth_from && (
                      <div className={style["error-message"]}>
                        {errors.auth_from}
                      </div>
                    )}
                  </div>
                </div>
                {/* </div> */}

                {/* <div className={style["input-wrapper"]}>
                  <InputField
                    type="select"
                    hint="Select Authority To"
                    label="Authority To"
                    name="auth_to"
                    value={values?.auth_to || ""}
                    onChange={(e) => {
                      setFieldValue("auth_to", e.target.value);
                      // setCheckRtoCode(e.target.value);
                      getAuthToGroupId(e.target.value);
                      resetRtoToChange(setFieldValue);
                    }}
                    required
                    onBlur={handleBlur}
                    items={
                      values.city_id && dropCityList.length === 0
                        ? [] // When city_id is found but dropCityList is empty, set items to an empty array
                        : dropCityList && dropCityList.length > 0
                        ? codeListAuthTo
                            .filter((ele) => dropCityList.includes(ele.regNo))
                            .map((ele) => ({
                              label: ele?.regNo,
                              value: ele?.id,
                            }))
                        : codeListAuthTo?.map((ele) => ({
                            label: ele?.regNo,
                            value: ele?.id,
                          }))
                    }
                  />
                </div> */}
                {/* <div className={style["input-wrapper"]}>
                  <div className={style["additional-wrapper"]}>
                    <label>Authority To</label>
                    <Select
                      className={style["dropdown-multiple"]}
                      value={
                        values.city_id && dropCityList.length === 0
                          ? null
                          : dropCityList && dropCityList.length > 0
                          ? codeListAuthTo
                              .filter((ele) => dropCityList.includes(ele.regNo))
                              .filter((ele) => ele.id === values.auth_to)
                              .map((ele) => ({
                                label: ele?.regNo,
                                value: ele?.id,
                              }))
                          : codeListAuthTo
                              ?.filter((ele) => ele.id === values.auth_to)
                              ?.map((ele) => ({
                                label: ele?.regNo,
                                value: ele?.id,
                              }))
                      }
                      name="auth_to"
                      options={
                        values.city_id && dropCityList.length === 0
                          ? [] // When city_id is found but dropCityList is empty, set items to an empty array
                          : dropCityList && dropCityList.length > 0
                          ? codeListAuthTo
                              .filter((ele) => dropCityList.includes(ele.regNo))
                              .map((ele) => ({
                                label: ele?.regNo,
                                value: ele?.id,
                              }))
                          : codeListAuthTo?.map((ele) => ({
                              label: ele?.regNo,
                              value: ele?.id,
                            }))
                      }
                      onChange={(e) => {
                        console.info("");
                        setFieldValue("auth_to", e.value);
                        // setCheckRtoCode(e.target.value);
                        getAuthToGroupId(e.value);
                        resetRtoToChange(setFieldValue);
                      }}
                      disabled={rtoCode}
                      onBlur={handleBlur}
                    />
                    {touched.auth_to && errors.auth_to && (
                      <div className={style["error-message"]}>
                        {errors.auth_to}
                      </div>
                    )}
                  </div>
                </div> */}
                <div className={style["input-wrapper"]}>
                  <div className={style["additional-wrapper"]}>
                    <label>Authority To</label>
                    <Select
                      className={style["dropdown-multiple"]}
                      value={
                        values.city_id && dropCityList.length === 0
                          ? null
                          : dropCityList && dropCityList.length > 0
                            ? codeListAuthTo
                              .filter((ele) => dropCityList.includes(ele.regNo))
                              .filter((ele) => ele.id === values.auth_to)
                              .map((ele) => ({
                                label: ele?.rto_name
                                  ? ele?.regNo + ` - ${ele?.rto_name}`
                                  : ele?.regNo,
                                value: ele?.id,
                              }))
                            : codeListAuthTo
                              ?.filter((ele) => ele.id === values.auth_to)
                              ?.map((ele) => ({
                                label: ele?.rto_name
                                  ? ele?.regNo + ` - ${ele?.rto_name}`
                                  : ele?.regNo,
                                value: ele?.id,
                              }))
                      }
                      isDisabled={true}
                      name="auth_to"
                      options={
                        values.city_id && dropCityList.length === 0
                          ? [] // When city_id is found but dropCityList is empty, set items to an empty array
                          : dropCityList && dropCityList.length > 0
                            ? codeListAuthTo
                              .filter((ele) => dropCityList.includes(ele.regNo))
                              .map((ele) => ({
                                label: ele?.rto_name
                                  ? ele?.regNo + ` - ${ele?.rto_name}`
                                  : ele?.regNo,
                                value: ele?.id,
                              }))
                            : codeListAuthTo?.map((ele) => ({
                              label: ele?.rto_name
                                ? ele?.regNo + ` - ${ele?.rto_name}`
                                : ele?.regNo,
                              value: ele?.id,
                            }))
                      }
                      onChange={(e) => {
                        setFieldValue("auth_to", e.value);
                        // setCheckRtoCode(e.target.value);
                        getAuthToGroupIdEdit(e.value);
                        resetRtoToChange(setFieldValue);
                        // setGroupToRtoId(e.value);
                      }}
                      disabled={rtoCode}
                      onBlur={handleBlur}
                    />
                    {touched.auth_to && errors.auth_to && (
                      <div className={style["error-message"]}>
                        {errors.auth_to}
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className={style["input-wrapper"]}>
                  <InputField
                    type={"search-dropdown"}
                    name="auth_to"
                    onChange={(id) => {
                      if (typeof id === "string") {
                        let data = codeList.find((ele) => ele?.regNo === id);
                        if (data) {
                          let strId = data?.id;
                          console.log("auth value string is ==>", strId);
                          setFieldValue("auth_to", strId);  
                        }
                      }
                      else if (typeof id === "number") {
                        console.log("auth value number is ==>", id);
                        setFieldValue("auth_to", id);  // Set the number directly
                      }                   
                    }}
                    onQueryChange={(value) => {
                      if (value) {
                        const filteredCodes = filteredCodeListAuthTo && filteredCodeListAuthTo.length > 0 ? filteredCodeListAuthTo.filter((ele) =>
                          ele?.regNo.toLowerCase().includes(value.toLowerCase())
                        ) : codeListAuthTo.filter((ele) =>
                          ele?.regNo.toLowerCase().includes(value.toLowerCase())
                        );
                        console.log('filteredCodes ==>', filteredCodes);
                        console.log('filtervalue ===>', value);
                        setCodeListAuthTo(filteredCodes);
                      } else {
                        getCodeList();
                      }
                    }}
                    onBlur={() => handleBlur("auth_to")}
                    hint={"Enter Authority"}
                    label={"Authority To"}
                    required
                    items={
                      dropCityList && dropCityList.length > 0
                        ? dropCityList[0]?.rto_codes_mapping
                          ? JSON.parse(dropCityList[0]?.rto_codes_mapping).map((rto) => ({
                            label: rto,
                            value : rto
                          }))
                          : [] 
                        : filteredCodeListAuthTo && filteredCodeListAuthTo.length > 0
                          ? filteredCodeListAuthTo.map((ele) => ({
                            label: ele?.regNo,
                            value: ele?.id,
                          }))
                          : codeListAuthTo.map((ele) => ({
                            label: ele?.regNo,
                            value: ele?.id,
                          }))
                    } 
                    value={values.auth_to || ""}
                    error={touched?.auth_to && errors?.auth_to}
                    dropdownHeight={"300px"}
                    renderRow={(item) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "10px 15px",
                          gap: "7px",
                          borderBottom: "1px solid #ebebeb",
                        }}
                      >
                        <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                          {item?.label}
                        </p>
                      </div>
                    )}
                  />
                </div> */}

                <div className={style["input-wrapper"]}>
                  <InputField
                    type={"text"}
                    label={"Seller Name"}
                    hint={"Enter Seller Name"}
                    name="seller_name"
                    value={values?.seller_name}
                    onBlur={handleBlur}
                    onChange={(value) => setFieldValue("seller_name", value)}
                    error={touched?.seller_name && errors?.seller_name}
                    disabled
                  />
                </div>
                <div className={style["input-wrapper"]}>
                  <InputField
                    type={"number"}
                    label={"Seller Phone No"}
                    hint={"Enter Seller Number"}
                    name="seller_phone"
                    value={values.seller_phone}
                    onBlur={handleBlur}
                    onChange={(value) => setFieldValue("seller_phone", value)}
                    error={touched?.seller_phone && errors?.seller_phone}
                    length={10}
                    disabled
                  />
                </div>
                {/* Add new column*/}
                <div className={style["input-wrapper"]}>
                  <div className={style["radio-input-container"]}>
                    <p className={style["radio-wrapper-text"]}>HPT</p>
                    <div className={style["radio-input"]}>
                      <div className={style["radio-sub-input"]}>
                        <input
                          type="radio"
                          name="hpt_radio"
                          disabled
                          checked={values?.hpt_type == "yes"}
                          onChange={() => {
                            setFieldValue("hpt_type", "yes");
                            handleServiceAmount(
                              { ...values, hpt_type: "yes" },
                              setFieldValue
                            );
                            autoTaskSuggestion(
                              values,
                              "yes",
                              setFieldValue,
                              "hpt"
                            );
                          }}
                          style={{
                            padding: "10px",
                            width: "15px",
                            height: "15px",
                          }}
                        />
                        <p>Yes</p>
                      </div>
                      <div className={style["radio-sub-input"]}>
                        <input
                          type="radio"
                          name="hpt_radio"
                          disabled
                          checked={values?.hpt_type == "no"}
                          onChange={() => {
                            setFieldValue("hpt_type", "no");
                            handleServiceAmount(
                              { ...values, hpt_type: "no" },
                              setFieldValue
                            );
                            autoTaskSuggestion(
                              values,
                              "no",
                              setFieldValue,
                              "hpt"
                            );
                          }}
                          style={{
                            padding: "10px",
                            width: "15px",
                            height: "15px",
                          }}
                        />
                        <p>No</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={style["input-wrapper"]}>
                  <div className={style["radio-input-container"]}>
                    <p className={style["radio-wrapper-text"]}>NOC</p>
                    <div className={style["radio-input"]}>
                      <div className={style["radio-sub-input"]}>
                        <input
                          type="radio"
                          name="noc_radio"
                          disabled
                          checked={values?.noc_type == "yes"}
                          onChange={() => {
                            setFieldValue("noc_type", "yes");
                            handleServiceAmount(
                              { ...values, noc_type: "yes" },
                              setFieldValue
                            );
                            autoTaskSuggestion(
                              values,
                              "yes",
                              setFieldValue,
                              "noc"
                            );
                          }}
                          style={{
                            padding: "10px",
                            width: "15px",
                            height: "15px",
                          }}
                        />
                        <p>Yes</p>
                      </div>
                      <div className={style["radio-sub-input"]}>
                        <input
                          type="radio"
                          name="noc_radio"
                          disabled
                          checked={values?.noc_type == "no"}
                          onChange={() => {
                            setFieldValue("noc_type", "no");
                            handleServiceAmount(
                              { ...values, noc_type: "no" },
                              setFieldValue
                            );
                            autoTaskSuggestion(
                              values,
                              "no",
                              setFieldValue,
                              "noc"
                            );
                          }}
                          style={{
                            padding: "10px",
                            width: "15px",
                            height: "15px",
                          }}
                        />
                        <p>No</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={style["input-wrapper"]}>
                  <div className={style["radio-input-container"]}>
                    <p className={style["radio-wrapper-text"]}>HPA</p>
                    <div className={style["radio-input"]}>
                      <div className={style["radio-sub-input"]}>
                        <input
                          type="radio"
                          name="hpa_radio"
                          disabled
                          checked={values?.hpa_type == "yes"}
                          onChange={() => {
                            setFieldValue("hpa_type", "yes");
                            handleServiceAmount(
                              { ...values, hpa_type: "yes" },
                              setFieldValue
                            );
                            autoTaskSuggestion(
                              values,
                              "yes",
                              setFieldValue,
                              "hpa"
                            );
                          }}
                          style={{
                            padding: "10px",
                            width: "15px",
                            height: "15px",
                          }}
                        />
                        <p>Yes</p>
                      </div>
                      <div className={style["radio-sub-input"]}>
                        <input
                          type="radio"
                          name="hpa_radio"
                          disabled
                          checked={values?.hpa_type == "no"}
                          onChange={() => {
                            setFieldValue("hpa_type", "no");
                            handleServiceAmount(
                              { ...values, hpa_type: "no" },
                              setFieldValue
                            );
                            autoTaskSuggestion(
                              values,
                              "no",
                              setFieldValue,
                              "hpa"
                            );
                          }}
                          style={{
                            padding: "10px",
                            width: "15px",
                            height: "15px",
                          }}
                        />
                        <p>No</p>
                      </div>
                    </div>
                  </div>
                </div>
                {values?.noc_type === "yes" && (
                  <div className={style["input-wrapper"]}>
                    <div className={style["radio-input-container"]}>
                      <p className={style["radio-wrapper-text"]}>Noc only</p>
                      <div className={style["radio-input"]}>
                        <div className={style["radio-sub-input"]}>
                          <input
                            type="radio"
                            name="noc_only"
                            disabled
                            checked={values?.noc_only_type == "yes"}
                            onChange={() => {
                              setFieldValue("noc_only_type", "yes");
                              handleServiceAmount(
                                { ...values, noc_only_type: "yes" },
                                setFieldValue
                              );
                              autoTaskSuggestion(
                                values,
                                "yes",
                                setFieldValue,
                                "noc_only"
                              );
                            }}
                            style={{
                              padding: "10px",
                              width: "15px",
                              height: "15px",
                            }}
                          />
                          <p>Yes</p>
                        </div>
                        <div className={style["radio-sub-input"]}>
                          <input
                            type="radio"
                            name="noc_only"
                            disabled
                            checked={values?.noc_only_type == "no"}
                            onChange={() => {
                              setFieldValue("noc_only_type", "no");
                              handleServiceAmount(
                                { ...values, noc_only_type: "no" },
                                setFieldValue
                              );
                              autoTaskSuggestion(
                                values,
                                "no",
                                setFieldValue,
                                "noc_only"
                              );
                            }}
                            style={{
                              padding: "10px",
                              width: "15px",
                              height: "15px",
                            }}
                          />
                          <p>No</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div style={{ width: "100%" }}></div>
                {/* END*/}

                <div className={style["input-wrapper"]}>
                  <InputField
                    label={"Task"}
                    hint={"Select Tasks"}
                    type={"select"}
                    name="task_id"
                    // value={suggestedTask ? suggestedTask.id : values?.task_id}
                    value={values?.task_id}
                    onChange={(e) => {
                      setFieldValue("task_id", e.target.value);
                      // getRtoAmount(e.target.value, "main");
                      taskChangeValueReset(e.target.value, setFieldValue);
                    }}
                    onBlur={handleBlur}
                    error={touched?.task_id && errors?.task_id}
                    required
                    items={taskList?.map((ele) => ({
                      label: ele?.name,
                      value: ele?.id,
                    }))}
                    disabled={true}
                  />
                </div>
                <div className={style["input-wrapper"]}>
                  <div className={style["additional-wrapper"]}>
                    <label>Additional Tasks</label>
                    {/* <Select
                      isMulti
                      className={style["dropdown-multiple"]}
                      value={taskValue}
                      name="add_task_id"
                      options={additionalTaskList?.map((ele) => ({
                        label: ele?.name,
                        value: ele?.id,
                      }))}
                      onChange={(selectedOption) => {
                        setTaskValue(selectedOption);
                        const selectedIds = selectedOption.map(
                          (option) => option.value
                        );
                        const selectedIdsString = selectedIds.join(",");
                        setFieldValue("add_task_id", selectedIdsString);
                        getRtoAmount(selectedIdsString, "additional");
                      }}
                    /> */}
                    <Select
                      isMulti
                      className={style["dropdown-multiple"]}
                      name="add_task_id"
                      disabled={true}
                      options={additionalTaskList?.map((ele) => ({
                        label: ele?.name,
                        value: ele?.id,
                      }))}
                      value={values.add_task_id
                        ?.map((id) => {
                          const found = additionalTaskList?.find(
                            (ele) => String(ele?.id) === String(id)
                          );
                          return found
                            ? { label: found.name, value: found.id }
                            : null;
                        })
                        ?.filter(Boolean)}
                      onChange={(selectedOption) => {
                        // setTaskValue(selectedOption);
                        const selectedIds = selectedOption?.map(
                          (option) => option.value
                        );
                        const selectedIdsString = selectedIds.join(",");
                        console.log("selectedIds ===>", selectedIds);
                        setFieldValue("add_task_id", selectedIds);
                        // getRtoAmount(selectedIdsString, "additional");
                      }}
                      isDisabled={true}
                    />
                    {touched.add_task_id && errors.add_task_id && (
                      <div className={style["error-message"]}>
                        {errors.add_task_id}
                      </div>
                    )}
                  </div>
                </div>

                <div className={style["input-wrapper"]}>
                  <InputField
                    label={"Remarks"}
                    hint={"Enter Remarks"}
                    name="remarks"
                    value={values.remarks}
                    onBlur={handleBlur}
                    onChange={(value) => setFieldValue("remarks", value)}
                    error={touched?.remarks && errors?.remarks}
                    disabled={true}
                  />
                </div>

                <div className={style["input-wrapper"]}>
                  <InputField
                    type={"number"}
                    label={"Receivable Amount From Dealer"}
                    hint={"Enter Amount"}
                    name="receivable_amount_dealer"
                    value={recivelAmount}
                    onChange={(value) => {
                      console.log('receivable_amount_dealer ===>', value);
                      setRecivealAmount(value);
                      setFieldValue("receivable_amount_dealer", value);
                    }}
                    // value={rtoPrice}
                    onBlur={handleBlur}
                    error={
                      touched?.receivable_amount_dealer &&
                      errors?.receivable_amount_dealer
                    }
                    required
                    disabled={true}
                  />
                </div>

                <div className={style["input-wrapper"]}>
                  <InputField
                    type={"select"}
                    label={"Receivable Payment Status"}
                    hint={"Select Status"}
                    name="receivable_status"
                    value={values?.receivable_status}
                    onChange={(e) => {
                      console.log(e.target.value, "Receivable Payment Status");
                      setFieldValue("receivable_status", e.target.value);
                    }}
                    required
                    onBlur={handleBlur}
                    error={
                      touched?.receivable_status && errors?.receivable_status
                    }
                    items={[
                      { label: "Pending", value: 1 },
                      { label: "Received", value: 2 },
                      { label: "On Hold", value: 3 },
                    ]}
                    disabled={true}
                  />
                </div>

                <div className={style["input-wrapper"]}>
                  <InputField
                    type={"number"}
                    label={"Fee Amount"}
                    hint={"Enter Amount"}
                    value={feeAmount}
                    onChange={(value) => {
                      console.log("value===>", value);
                      setFieldValue("amount", value);
                      setFeeAmount(value);
                    }}
                    name="amount"
                    onBlur={handleBlur}
                    error={touched?.amount && errors?.amount}
                    required
                    disabled={true}
                  />
                </div>

                <div className={style["autocomplete-wrapper"]}>
                  <label
                    style={{
                      color: "black",
                      width: "200px",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    Dealership
                  </label>
                  <Autocomplete
                    label="Select Dealer"
                    className={style["dropdown-multiple"]}
                    style={{
                      backgroundColor: "white",
                      border: "none",
                    }}
                    disabled={true}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 45,
                      },
                    }}
                    value={selectedAgent}
                    onChange={(event, newValue) => {
                      console.log("newValue", newValue?.id);
                      setFieldValue("contact_agent_id", newValue?.id);
                      if (newValue?.id === "add_new") {
                        setAgentModalOpen(true);
                      } else {
                        setSelectedAgent(newValue);
                      }
                    }}
                    options={[
                      ...agents,
                      { name: "Add New User", id: "add_new" },
                    ]}
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.name
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Select Agent" />
                    )}
                    renderOption={(props, option) =>
                      option.name === "Add New User" ? (
                        <li
                          {...props}
                          style={{ color: "blue", fontWeight: "bold" }}
                        >
                          + {option.name}
                        </li>
                      ) : (
                        <li {...props}>{option.name}</li>
                      )
                    }
                    isOptionEqualToValue={(option, value) =>
                      typeof option === "string"
                        ? option === value
                        : option.id === value?.id
                    }
                  />
                  <Dialog
                    open={isAgentModalOpen}
                    onClose={() => setAgentModalOpen(false)}
                  >
                    <DialogTitle>Add New Agent</DialogTitle>
                    <DialogContent>
                      <TextField
                        autoFocus
                        margin="dense"
                        label="User Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={newAgentName}
                        onChange={(e) => setNewAgentName(e.target.value)}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button
                        className={style["reset-button"]}
                        onClick={() => setAgentModalOpen(false)}
                      >
                        Cancel
                      </Button>
                      <button
                        className={style["reset-button"]}
                        onClick={handleAddNewAgent}
                        variant="contained"
                        color="primary"
                      >
                        Save Agent
                      </button>
                    </DialogActions>
                  </Dialog>
                </div>

                <div className={style["input-wrapper"]}>
                  <InputField
                    type={"number"}
                    label={"Payable Amount To Agent"}
                    value={amountAgent}
                    hint={"Enter Amount"}
                    name="payable_amount_agent"
                    onChange={(value) => {
                      setAmountAgent(value);
                      setFieldValue("payable_amount_agent", value)
                    }}
                    onBlur={handleBlur}
                    error={
                      touched?.payable_amount_agent &&
                      errors?.payable_amount_agent
                    }
                    required
                    disabled={true}
                  />
                </div>
                <div className={style["input-wrapper"]}>
                  <InputField
                    type={"select"}
                    label={"Payable Amount Status"}
                    hint={"Select Status"}
                    name="payable_status"
                    value={values?.payable_status}
                    onChange={(e) => {
                      console.log("Payable Amount Status", e.target.value);
                      setFieldValue("payable_status", e.target.value);
                    }}
                    required
                    onBlur={handleBlur}
                    error={touched?.payable_status && errors?.payable_status}
                    items={[
                      { label: "Pending", value: 1 },
                      { label: "Received", value: 2 },
                      { label: "On Hold", value: 3 },
                    ]}
                    disabled={true}
                  />
                </div>
                <div className={style["input-wrapper"]}>
                  <InputField
                    type={"number"}
                    label={" Profit Amount"}
                    hint={"Profit Amount"}
                    name="profit_amount"
                    value={finalAmont}
                    onBlur={handleBlur}
                    error={touched?.profit_amount && errors?.profit_amount}
                    required
                    disabled
                  />
                </div>
              </div>
              {/* <div className={style["action-container"]}>
                <Link
                  to="/Services/RTOCases"
                  className={style["cancel-container"]}
                >
                  Cancel
                </Link>

                <button type="submit" className={style["reset-button"]}>
                  {loading ? "Loading..." : "Submit"}
                </button>
              </div> */}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ViewRTOCases;
