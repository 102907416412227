import React, { useEffect, useState } from "react";
import style from "./addNewCar.module.scss";
import BackButton from "../../components/buttons/BackButton";
import PrimaryHeading from "../../components/primary-heading/PrimaryHeading";
import InputField from "../../components/input-field/InputField";
import { dealerId, R } from "../../constants/resources";
import SelectableButton from "../../components/buttons/SelectableButton";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import ImageCard from "./components/image-card/ImageCard";
import { useDispatch, useSelector } from "react-redux";
import { publicApi } from "../../api/publicApi";
import { postCarDetails } from "../../store/car/carAction";
import { useNavigate } from "react-router-dom";
import MultiSelect from "../../components/buttons/multiSelect";
import DateField from "../../components/input-field/dateField";
import DatePickerYear from "../../components/input-field/yearDateField";
import { toast } from "react-toastify";
import { carApi } from "../../api/carApi";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import ErrorMessage from "../../components/input-field/ErrorMessage";
import SecondaryHeading from "../../components/primary-heading/SecondaryHeading";
import { formatIndianCurrency } from "../../utils/PrivateRoutes.js";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { geographicRegion } from "../../api/geoRegion.js";
import { fetchDealerAddress, fetchPurchesar, fetchSource } from "../../store/car/carAction";

export default function AddNewCar() {
  const dispatch = useDispatch();
  const [loaderTrue, setloaderTrue] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [coloList, setColorList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [varientList, setVarientList] = useState([]);
  const [imagesData, setImagesData] = useState([]);
  const [flags, setFlags] = useState([]);
  const [imageTypeEnum, setimageTypeEnum] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [geoRegion, setGeoRegion] = useState({ state: [], city: [] });
  const { purchaser, source, dealerAddress } = useSelector((state) => state?.car || []);


  const fuelTypes = {
    1: "Petrol",
    2: "Diesel",
    4: "CNG",
    3: "Electric (Battery)"
};

  useEffect(() => {
    dispatch(fetchDealerAddress());
    dispatch(fetchPurchesar())
    dispatch(fetchPurchesar())
  }, [dispatch]);




  const navigate = useNavigate();
  const brands = async () => {
    try {
      const res = await publicApi.brandList();
      setBrandList(res?.data);
      const res1 = await carApi.imgTypeEnumList();
      setimageTypeEnum(res1);
    } catch (err) {
      console.log(err);
    }
  };

  const colors = async () => {
    try {
      const res = await publicApi.getColors();
      setColorList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const [formData, setFormData] = useState({
    regNumber: "",
    makeId: "",
    fuelType: "",
    transmissionType: null,
    variantId: "",
    dealerId: 5,
    modelId: "",
    makeYear: null,
    regYear: null,
    colour: "",
    actual_price: "",
    ownerType: null,
    kmDriven: "",
    listingPrice: null,
    discountPercent: 0,
    insuranceType: "",
    carDescription: "",
    metaTag: "",
    isCngFitted: 0,
    isAssured: 0,
    isClassified: 0,
    isWebsite: 0,
    trending: 0,
    discounted: 0,
    newModel: 0,
    newStock: 0,
    insuranceExpMonth: "",
    insuranceExpYear: "",
    state: "",
    regCityId: "",
    showroomId: "",
    source: "",
    purchaser: ""
  });
  const [requiredError, setRequiredError] = useState("");


  const models = async () => {
    try {
      const res = await publicApi.modelList({
        make: formData?.makeId,
        year: formData?.makeYear,
      });
      setModelList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const varients = async () => {
    try {
      let fuelTypeVersion = fuelTypes[formData.fuelType];
      const res = await publicApi.varientList({ model: formData?.modelId,fuel_type: fuelTypeVersion });
      setVarientList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchAndSetGeoRegions = async () => {
    try {
      const stateData = await geographicRegion.getStateNames();
      setGeoRegion((prev) => ({ ...prev, state: stateData?.data }));
      const stateId = formData?.state;
      if (stateId) {
        const cityData = await geographicRegion.getCityNames({
          state: stateId,
        });
        setGeoRegion((prev) => ({ ...prev, city: cityData?.data }));
      } else {
        setGeoRegion((prev) => ({ ...prev, city: [] }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    brands();
    colors();
  }, []);

  useEffect(() => {
    fetchAndSetGeoRegions();
  }, [formData.state]);

  useEffect(() => {
    if (formData?.makeId && formData.makeYear) {
      models();
    }
  }, [formData?.makeId, formData.makeYear]);

  useEffect(() => {
    if (formData?.fuelType ) varients();
  }, [formData?.fuelType]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]:
        name === "listingPrice" ? value.toString().replace(/,/g, "") : value,
    });
  };


  const addCar = (e) => {
    e.preventDefault();
    if (formData.insuranceType == "5") {
      delete formData.insuranceExpYear;
      delete formData.insuranceExpMonth;
    }
    if (
      !formData.makeId ||
      !formData.fuelType ||
      !formData.transmissionType ||
      !formData?.variantId ||
      !formData.modelId ||
      !formData.makeYear ||
      !formData.listingPrice ||
      !formData.discountPercent === null ||
      !formData.discountPercent === ""
    ) {
      setRequiredError(true);
      return;
    }

    // Calculate actual price
    const actualPrice =
      (formData.listingPrice * formData.discountPercent) / 100 +
      Number(formData.listingPrice);

    const payload = {
      ...formData,
      actual_price: actualPrice,
      makeYear: formData.makeYear ? Number(formData.makeYear) : 0,
      discountPercent: formData.discountPercent
        ? parseInt(formData.discountPercent, 10)
        : 0,

      kmDriven: formData.kmDriven ? Number(formData.kmDriven) : 0,
      listingPrice: formData.listingPrice ? Number(formData.listingPrice) : 0,
      ...(formData.insuranceType !== "5" && {
        insuranceExpYear: Number(formData?.insuranceExpYear),
        insuranceExpMonth: Number(formData?.insuranceExpMonth),
      }),
      isCngFitted: formData.isCngFitted
        ? String(Number(formData.isCngFitted))
        : "0",
      isAssured: formData.isAssured ? String(formData.isAssured) : "0",
      isClassified: formData.isClassified ? String(formData.isClassified) : "0",
      isWebsite: formData.isWebsite ? String(formData.isWebsite) : "0",
      trending: formData.trending
        ? String(Number(formData.trending))
        : undefined,
      discounted: formData.discounted
        ? String(Number(formData.discounted))
        : undefined,
      newModel: formData.newModel
        ? String(Number(formData.newModel))
        : undefined,
      newStock: formData.newStock
        ? String(Number(formData.newStock))
        : undefined,

      regNumber: formData.regNumber ? formData.regNumber : undefined,
      regYear: formData.regYear ? formData.regYear : undefined,
      colour: formData.colour ? formData.colour : undefined,
      regCityId: formData.regCityId ? formData.regCityId : undefined,
      state: formData.state ? formData.state : undefined,
      insuranceExpMonth: formData.insuranceExpMonth
        ? formData.insuranceExpMonth
        : undefined,
      insuranceExpYear: formData.insuranceExpYear
        ? formData.insuranceExpYear
        : undefined,
      insuranceType: formData.insuranceType
        ? formData.insuranceType
        : undefined,
      isAssured: formData.isAssured ? formData.isAssured : undefined,
      insuranceType: formData.insuranceType ? formData.insuranceType : "0",
      showroomId: formData.showroomId ? formData.showroomId : undefined,
      source: formData.source ? formData.source : undefined
    };

    // Sending the form data directly using postCarDetails
    setloaderTrue(true);

    postCarDetails(payload)
      .then((res) => {
        if (res.status === true) {
          // console.log(res?.data?.carSkuId);
          navigate("/upload-images", { state: `${res?.data?.carSkuId}` });
        } else {
          console.log("running toast condition");
          res?.message.map((errr) =>
            toast.error(errr || "Something went wrong.")
          );
        }
        setloaderTrue(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.msg || "Internal Server Error");
        setloaderTrue(false);
      });
  };

  const handleYearChange = (newValue) => {
    const year = moment(newValue).year();
    setFormData((prevFormData) => ({
      ...prevFormData,
      regYear: year,
    }));
  };

  const handleallYearChange = (newValue, name) => {
    const year = moment(newValue).year();

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: year,
    }));
  };

  const handleRegYearChange = (newValue) => {
    const year = moment(newValue).year();
    setFormData((prevFormData) => ({
      ...prevFormData,
      makeYear: year,
    }));
  };

  const handleCheckbox = (event, name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: event.target.checked ? 1 : 0,
    }));
  };

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      insuranceExpMonth: selectedMonth,
    }));
  };



  const InventryMetaData = localStorage.getItem("is_inventory_metadata");
 

  return (
    <>
      <div className={style["container"]}>
        {loaderTrue ? (
          <div class="loading">
            <div class="loader"></div>
          </div>
        ) : null}

        <Box
          sx={{
            width: "100%",
            display: "flex",
            backgroundColor: "#fff",
            borderRadius: "5px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",

            alignItems: "center",

            padding: "15px ",
          }}
        >
          <BackButton
            onClick={() => {
              navigate("/car-management");
            }}
          />
          <Typography
            variant="h6"
            component="div"
            sx={{ fontWeight: 600, px: 2 }}
          >
            Add New Car
          </Typography>
        </Box>

        <div className={style["wrapper"]}>
          <div className={style["vehicle-details-container"]}>
            <SecondaryHeading
              title={"Vehicle Details"}
              fontSize={"1.5rem"}
              fontWeight={500}
            />

            <div className={style['first-container']}>
              <div className={style['left-side-current']}>
                <div className={style['input-wrapper']}>
                  <p className={style["label"]}>
                    Make <span style={{ color: "red" }}>*</span>
                  </p>
                  <div className={style['select-desin']}>
                    <MultiSelect
                      name="makeId"
                      handleChange={(e) =>
                        setFormData({ ...formData, makeId: e.value })
                      }
                      items={brandList?.map((ele) => ({
                        label: ele?.makeName,
                        value: ele?.makeId,
                      }))}
                      selectedItems={formData?.makeId}
                      hint={"Select Brand"}
                      isSearchable={true}
                      required={true}
                    />
                    <ErrorMessage
                      errorMessage={!formData?.makeId && "Please select brand"}
                      requiredError={
                        !formData?.makeId?.length > 0 ? requiredError : ""
                      }
                    />

                  </div>
                </div>

                <div className={style['input-wrapper']}>
                  <p className={style["label"]}>
                    Model <span style={{ color: "red" }}>*</span>
                  </p>
                  <div className={style['select-desin']}>
                    <MultiSelect
                      name="modelId"
                      handleChange={(e) =>
                        setFormData({ ...formData, modelId: e.value })
                      }
                      items={modelList?.map((ele) => ({
                        label: ele?.groupModelName,
                        value: ele?.modelId,
                      }))}
                      selectedItems={formData?.modelId}
                      hint={"Select Model"}
                      isSearchable={true}
                      containerStyle={style["selectable-button-container-style"]}
                      selectClass={style["filterDropDown"]}
                      required={true}
                    />
                    <ErrorMessage
                      errorMessage={!formData?.modelId && "Please select model"}
                      requiredError={!formData?.modelId?.length > 0 && requiredError}
                    />

                  </div>
                </div>

                <div className={style["input-wrapper"]}>
                  <p className={style["label"]}>
                    Transmission <span style={{ color: "red" }}>*</span>
                  </p>
                  <div className={style['select-desin']}>
                    <SelectableButton
                      trailingIcon={R.ic_arrow_down}
                      containerStyle={style["selectable-button-container-style"]}
                      name="transmissionType"
                      handleChange={(e) => {
                        setFormData({
                          ...formData,
                          transmissionType: parseInt(e.target.value, 10),
                        });
                      }}
                      items={[
                        { label: "Manual", value: 1 },
                        { label: "Automatic", value: 2 },
                      ]}
                      value={formData?.transmissionType}
                      hint={"Select Transmission"}
                      required={true}
                    />
                    <ErrorMessage
                      errorMessage={
                        !formData?.transmissionType && "Please select transmission"
                      }
                      requiredError={
                        !formData?.transmissionType?.length > 0 && requiredError
                      }
                    />

                  </div>
                </div>

                
                
                <div className={style['input-wrapper']}>
                  <p className={style["label"]} style={{ marginBottom: 10 }}>
                    Reg Year <span style={{ color: "red" }}></span>
                  </p>
                  <div className={style['select-desin']}>
                    <LocalizationProvider
                      dateAdapter={AdapterMoment}
                      adapterLocale="de"
                      sx={{
                        "& .MuiStack-root": {
                          paddingTop: "0px !important",
                        },
                      }}
                    >
                      {/* <DemoContainer components={["DatePicker", "DatePicker"]}> */}
                      <DatePicker
                        size="small"
                        openTo="year"
                        views={["year"]}
                        required={true}
                        value={
                          formData.regYear ? moment().year(formData.regYear) : null
                        }
                        onChange={handleYearChange}
                        // className={style["custom-year-picker"]}/
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-root": {
                            height: "36px !important",
                          },
                          "& .MuiStack-root": {
                            paddingTop: "0px !important",
                          },
                        }}
                      />
                      {/* </DemoContainer> */}
                    </LocalizationProvider>
                  </div>
                </div>
                <div className={style['input-wrapper']}>
                  <p className={style["label"]}>
                    KM Driven <span style={{ color: "red" }}>*</span>
                  </p>
                  <div className={style['select-desin']}>
                    <InputField
                      placeholder={"Enter KM Driven"}
                      name="kmDriven"
                      handleChange={handleChange}
                      value={formData?.kmDriven}
                      length={6}
                      regexPattern={/^\d+$/}
                      errorMessage={
                        formData?.kmDriven
                          ? "Km should be valid"
                          : "Please enter driven km"
                      }
                      autoComplete="off"
                      requiredError={!formData?.kmDriven?.length > 0 && requiredError}
                    />
                  </div>
                </div>
                
                <div className={style["input-wrapper"]}>
                  <p className={style["label"]}>
                    Insurance Type <span style={{ color: "red" }}></span>
                  </p>
                  <div className={style['select-desin']}>
                    <SelectableButton
                      trailingIcon={R.ic_arrow_down}
                      name="insuranceType"
                      handleChange={(e) => {
                        setFormData({
                          ...formData,
                          insuranceType: e.target.value,
                        });
                      }}
                      items={[
                        { description: "Comprehensive", id: 1 },
                        { description: "Zero Dep", id: 2 },
                        { description: "Own Damage", id: 3 },
                        { description: "Third Party", id: 4 },
                        { description: "No Insurance", id: 5 },
                      ]?.map((ele) => ({
                        label: ele?.description,
                        value: ele?.id,
                      }))}
                      value={formData?.insuranceType}
                      hint={"Select insurance type"}
                      required={true}
                    />
                  </div>
                </div>

                { formData?.insuranceType !== "5" ? <>
                <div className={style["input-wrapper"]}>
                  <p className={style["label"]}>Insurance Valid Month</p>
                  <div className={style['select-desin']}>
                    <SelectableButton
                      trailingIcon={R.ic_arrow_down}
                      name="insuranceExpMonth"
                      handleChange={handleMonthChange}
                      items={[
                        { id: 1, name: "January" },
                        { id: 2, name: "February" },
                        { id: 3, name: "March" },
                        { id: 4, name: "April" },
                        { id: 5, name: "May" },
                        { id: 6, name: "June" },
                        { id: 7, name: "July" },
                        { id: 8, name: "August" },
                        { id: 9, name: "September" },
                        { id: 10, name: "October" },
                        { id: 11, name: "November" },
                        { id: 12, name: "December" },
                      ]?.map((ele) => ({
                        label: ele?.name,
                        value: ele?.id,
                      }))}
                      value={
                        formData?.insuranceType !== "5"
                          ? formData?.insuranceExpMonth
                          : ""
                      }
                      hint={"Select insurance expiry month"}
                      required={true}
                    />
                  </div>
                </div>
                </>
                :  null }

                <div className={style["input-wrapper"]}>
                  <label>
                    <input
                      className={style["input-checkbox"]}
                      type="checkbox"
                      checked={formData.isCngFitted}
                      onChange={(e) => handleCheckbox(e, "isCngFitted")}
                    />
                    <span style={{ paddingLeft: "10px" }}>Is CNG Fitted</span>
                  </label>
                </div>
                {/* <div className={style['input-wrapper']}>
                  <p className={style["label"]}>
                    City <span style={{ color: "red" }}></span>
                  </p>
                  <div className={style['select-desin']}>
                    <MultiSelect
                      name="regCityId"
                      handleChange={(e) =>
                        setFormData({ ...formData, regCityId: e.value })
                      }
                      items={geoRegion.city?.map((ele) => ({
                        label: ele?.cityName,
                        value: ele?.cityId,
                      }))}
                      selectedItems={formData?.regCityId}
                      hint={"Select City"}
                      containerStyle={style["selectable-button-container-style"]}
                      selectClass={style["filterDropDown"]}
                      required={true}
                    />
                  </div>
                </div> */}
                


              </div>
              <div className={style['right-side-current']}>
                <div className={style['input-wrapper']}>
                  <p className={style["label"]}>
                    Make Year <span style={{ color: "red" }}>*</span>
                  </p>
                  <div className={style['select-desin']}>
                    <LocalizationProvider
                      dateAdapter={AdapterMoment}
                      adapterLocale="de"
                      sx={{
                        "& .MuiStack-root": {
                          paddingTop: "10px !important",
                        },
                      }}
                    >
                      <DatePicker
                        size="small"
                        openTo="year"
                        views={["year"]}
                        value={
                          formData.makeYear ? moment().year(formData.makeYear) : null
                        }
                        onChange={handleRegYearChange}
                        required={true}
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-root": {
                            height: "36px !important",
                          },
                          "& .MuiStack-root": {
                            paddingTop: "0px !important",
                          },
                        }}
                      />
                    </LocalizationProvider>
                    <ErrorMessage
                      errorMessage={!formData.makeYear && "Please select year"}
                      requiredError={!formData.makeYear?.length > 0 && requiredError}
                    />
                  </div>
                </div>
                
                <div className={style['input-wrapper']}>
                  <p className={style["label"]}>
                    Fuel Type <span style={{ color: "red" }}>*</span>
                  </p>
                  <div className={style['select-desin']}>
                    <SelectableButton
                      trailingIcon={R.ic_arrow_down}
                      containerStyle={style["selectable-button-container-style"]}
                      name="fuelType"
                      handleChange={(e) => {
                        setFormData({ ...formData, fuelType: e.target.value });
                      }}
                      items={[
                        { label: "Petrol", value: 1 },
                        { label: "Deisel", value: 2 },
                        { label: "Electric", value: 3 },
                        { label: "CNG", value: 4 },
                      ]}
                      value={formData?.fuelType}
                      hint={"Select Fuel Type"}
                      required={true}
                    />
                    <ErrorMessage
                      errorMessage={!formData?.fuelType && "Please select fuel"}
                      requiredError={!formData?.fuelType?.length > 0 && requiredError}
                    />

                  </div>
                </div>
                <div className={style['input-wrapper']}>
                  <p className={style["label"]}>
                    Variant <span style={{ color: "red" }}>*</span>
                  </p>
                  <div className={style['select-desin']}>
                    <MultiSelect
                      name="variantId"
                      handleChange={(e) => {
                        console.log("eee", e);
                        setFormData({ ...formData, variantId: e.value });
                      }}
                      items={varientList?.map((ele) => ({
                        label: ele?.variantName,
                        value: ele?.variantId,
                      }))}
                      selectedItems={formData?.variantId}
                      hint={"Select Variant"}
                      containerStyle={style["selectable-button-container-style"]}
                      selectClass={style["filterDropDown"]}
                      required={true}
                    />
                    <ErrorMessage
                      errorMessage={!formData?.variantId && "Please select variant"}
                      requiredError={
                        !formData?.variantId?.length > 0 && requiredError
                      }
                    />

                  </div>
                </div>
                <div className={style['input-wrapper']}>
                  <p className={style["label"]}>
                    Vehicle Number <span style={{ color: "red" }}></span>
                  </p>
                  <div className={style['select-desin']}>
                    <InputField
                      placeholder={"Vehicle Number"}
                      name="regNumber"
                      handleChange={handleChange}
                      value={formData?.regNumber?.toUpperCase()}
                      regexPattern={/^(?=[A-Za-z]{2})(?=.*[A-Z]).{2,11}$/}
                      errorMessage={
                        formData.regNumber
                          ? "Invalid Vehicle Number"
                          : "Please select vehicle number"
                      }
                      autoComplete="off"
                    // requiredError={
                    //   !formData.regNumber.length > 0 ? requiredError : ""
                    // }
                    />
                  </div>
                </div>

                <div className={style['input-wrapper']}>
                  <p className={style["label"]}>
                    Owner <span style={{ color: "red" }}>*</span>
                  </p>
                  <div className={style['select-desin']}>
                    <SelectableButton
                      trailingIcon={R.ic_arrow_down}
                      containerStyle={style["selectable-button-container-style"]}
                      name="ownerType"
                      handleChange={(e) => {
                        setFormData({ ...formData, ownerType: e.target.value });
                      }}
                      items={[
                        { label: "First", value: 1 },
                        { label: "Second", value: 2 },
                        { label: "Third", value: 3 },
                        { label: "Fourth", value: 4 },
                        { label: "Fourth+", value: 5 },
                      ]}
                      value={formData?.ownerType}
                      hint={"Select Owner"}
                      required={true}
                    />
                    <ErrorMessage
                      errorMessage={!formData?.ownerType && "Please select owner"}
                      requiredError={
                        !formData?.ownerType?.length > 0 && requiredError
                      }
                    />

                  </div>
                </div>
                {/* <div className={style['input-wrapper']}>
                  <p className={style["label"]}>
                    State <span style={{ color: "red" }}></span>
                  </p>
                  <div className={style['select-desin']}>
                    <MultiSelect
                      name="state"
                      handleChange={(e) =>
                        setFormData({ ...formData, state: e.value })
                      }
                      items={geoRegion.state?.map((ele) => ({
                        label: ele?.stateName,
                        value: ele?.stateListId,
                      }))}
                      selectedItems={formData?.state}
                      hint={"Select State"}
                      containerStyle={style["selectable-button-container-style"]}
                      selectClass={style["filterDropDown"]}
                      required={true}
                    />
                  </div>
                </div> */}

                <div className={style['input-wrapper']}>
                  <p className={style["label"]}>
                    Color <span style={{ color: "red" }}></span>
                  </p>
                  <div className={style['select-desin']}>
                    <MultiSelect
                      name="colour"
                      handleChange={(e) =>
                        setFormData({ ...formData, colour: e.value })
                      }
                      items={coloList?.map((ele) => ({
                        label: ele?.colorName,
                        value: ele?.colorId,
                      }))}
                      selectedItems={formData?.colour}
                      hint={"Select Color"}
                      containerStyle={style["selectable-button-container-style"]}
                      selectClass={style["filterDropDown"]}
                      required={true}
                    />
                  </div>
                </div>

                
                

                { formData?.insuranceType !== "5" ? <>
                <div className={style["input-wrapper"]}>
                  <p className={style["label"]}>Insurance Valid Year</p>
                  <div className={style['select-desin']}>
                    <LocalizationProvider
                      dateAdapter={AdapterMoment}
                      adapterLocale="de"
                      sx={{
                        "& .MuiStack-root": {
                          paddingTop: "10px !important",
                        },
                      }}
                    >
                      {/* <DemoContainer components={["DatePicker", "DatePicker"]}> */}
                      <DatePicker
                        size="small"
                        openTo="year"
                        views={["year"]}
                        value={
                          formData?.insuranceType !== "5"
                            ? formData.insuranceExpYear
                              ? moment().year(formData.insuranceExpYear)
                              : null
                            : null
                        }
                        onChange={(newValue) =>
                          handleallYearChange(newValue, "insuranceExpYear")
                        }
                        required={true}
                        // className={style["custom-year-picker"]}/
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-root": {
                            height: "36px !important",
                          },
                          "& .MuiStack-root": {
                            paddingTop: "0px !important",
                          },
                        }}
                      />
                      {/* </DemoContainer> */}
                    </LocalizationProvider>
                  </div>
                </div>
                </> : null }
              </div>
            </div>
          </div>
          <div className={style["price-container"]}>
            <SecondaryHeading
              title={"Price"}
              fontSize={"1.5rem"}
              fontWeight={500}
            />

            <div className={style['first-container']}>
              <div className={style['left-side-current']}>
                <div className={style['input-wrapper']}>
                  <p className={style["label"]}>
                    Price <span style={{ color: "red" }}>*</span>
                  </p>
                  <div className={style['select-desin']}>
                    <InputField
                      placeholder={"Enter Price"}
                      name="listingPrice"
                      handleChange={handleChange}
                      value={formatIndianCurrency(formData?.listingPrice)}
                      length={9}
                      regexPattern={/^[\d,]+$/}
                      errorMessage={
                        formData?.listingPrice
                          ? "Price should be valid"
                          : "Please enter price"
                      }
                      requiredError={
                        !formData?.listingPrice?.length > 0 && requiredError
                      }
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className={style['input-wrapper']}>
                  <p className={style["label"]}>
                    Max Selling Price <span style={{ color: "red" }}>*</span>
                  </p>
                  <div className={style['select-desin']}>
                    <InputField
                      placeholder={"Enter Max Selling Price"}
                      name="actual_price"
                      handleChange={handleChange}
                      value={formatIndianCurrency(
                        Math.ceil(
                          (formData?.listingPrice * formData?.discountPercent) / 100
                        ) + Number(formData?.listingPrice)
                      )}
                      disabled={true}
                      required={true}
                    />
                  </div>
                </div>
              </div>
              <div className={style['right-side-current']}>
                <div className={style['input-wrapper']}>
                  <p className={style["label"]}>
                    Discount Percent (%) <span style={{ color: "red" }}>*</span>
                  </p>
                  <div className={style['select-desin']}>
                    <InputField
                      placeholder={"Enter Discount %"}
                      name="discountPercent"
                      handleChange={handleChange}
                      value={formData?.discountPercent}
                      length={3}
                      regexPattern={/^\d+$/}
                      errorMessage={
                        formData?.discountPercent === "" &&
                          formData?.discountPercent === null
                          ? "Please enter discount price"
                          : "Discount should be valid"
                      }
                      requiredError={
                        !formData?.discountPercent === null &&
                        formData?.discountPercent !== "" &&
                        requiredError
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={style["Other-Information"]}>
            <SecondaryHeading
              title={"Other Information"}
              fontSize={"1.5rem"}
              fontWeight={500}
            />
            <div className={style['first-container']}>
              <div className={style['left-side-current']}>
                {/* <div className={style['input-wrapper']}>
                  <p className={style["label"]}>
                    Fuel Type <span style={{ color: "red" }}>*</span>
                  </p>
                  <div className={style['select-desin']}>
                    <SelectableButton
                      trailingIcon={R.ic_arrow_down}
                      containerStyle={style["selectable-button-container-style"]}
                      name="fuelType"
                      handleChange={(e) => {
                        setFormData({ ...formData, fuelType: e.target.value });
                      }}
                      items={[
                        { label: "Petrol", value: 1 },
                        { label: "Deisel", value: 2 },
                        { label: "Electric", value: 3 },
                        { label: "CNG", value: 4 },
                      ]}
                      value={formData?.fuelType}
                      hint={"Select Fuel Type"}
                      required={true}
                    />
                    <ErrorMessage
                      errorMessage={!formData?.fuelType && "Please select fuel"}
                      requiredError={!formData?.fuelType?.length > 0 && requiredError}
                    />

                  </div>
                </div> */}


                {InventryMetaData === "1" && (
                  <div className={style['input-wrapper']}>
                    <p className={style["label"]}>
                      Purchaser
                    </p>
                    <div className={style['select-desin']}>
                      <MultiSelect
                        name="purchaser"
                        handleChange={(e) =>
                          setFormData({ ...formData, purchaser: e.value })
                        }
                        items={purchaser?.map((ele) => ({
                          label: ele?.name,
                          value: ele?.id,
                        }))}
                        selectedItems={formData?.purchaser}
                        hint={"Select Purchaser"}
                      />

                    </div>
                  </div>
                )}

                <div className={style["input-wrapper"]}>
                  <p className={style["label"]}>Showroom  <span style={{ color: "red" }}>*</span></p>
                  <div className={style['select-desin']}>
                    <SelectableButton
                      trailingIcon={R.ic_arrow_down}
                      containerStyle={style["selectable-button-container-style"]}
                      name="showroomId"
                      handleChange={(e) => {
                        const data = +e.target.value;
                        setFormData({ ...formData, showroomId: +data });
                      }}
                      items={dealerAddress.map(res => ({
                        label: res.address1,
                        value: res?.id
                      }))}
                      value={formData?.showroomId}
                      hint={"Select show room address"}
                      required={true}
                    />
                    <ErrorMessage
                      errorMessage={!formData?.showroomId && "Please select showroom address"}
                      requiredError={!formData?.showroomId?.length > 0 && requiredError}
                    />
                  </div>

                </div>



                
                

                

              </div>
              <div className={style['right-side-current']}>


              {InventryMetaData === "1" && (
                  <div className={style["input-wrapper"]}>
                    <p className={style["label"]}>
                    Purchaser Source
                    </p>
                    <div className={style['select-desin']}>
                      <MultiSelect
                        name="source"
                        handleChange={(e) =>
                          setFormData({ ...formData, source: e.value })
                        }
                        items={source.map((ele) => ({
                          label: ele?.name,
                          value: ele?.id,
                        }))}
                        selectedItems={formData?.source}
                        hint={"Select Purchaser Source"}
                      />

                    </div>
                  </div>
                )}

              </div>
            </div>
          </div>

          <div className="tags">
            <SecondaryHeading
              title={"Tags"}
              fontSize={"1.5rem"}
              fontWeight={500}
            />

            <div className={style['tag-first-container']}>

              <div className={style['tages-first-current']}>

                <div className={style['input-wrapper']}>
                  <label>
                    <input
                      className={style["input-checkbox"]}
                      type="checkbox"
                      checked={formData.trending}
                      onChange={(e) => handleCheckbox(e, "trending")}
                    />
                    <span style={{ paddingLeft: "10px" }}>Trending</span>
                  </label>
                </div>

                <div className={style["input-wrapper"]}>
                  <label>
                    <input
                      className={style["input-checkbox"]}
                      type="checkbox"
                      checked={formData.newModel}
                      onChange={(e) => handleCheckbox(e, "newModel")}
                    />
                    <span style={{ paddingLeft: "10px" }}>New Model</span>
                  </label>

                </div>

                <div className={style["input-wrapper"]}>
                  <label>
                    <input
                      className={style["input-checkbox"]}
                      type="checkbox"
                      checked={formData.discounted}
                      onChange={(e) => handleCheckbox(e, "discounted")}
                    />
                    <span style={{ paddingLeft: "10px" }}>Discounted</span>
                  </label>

                </div>

                <div className={style['input-wrapper']}>
                  <label>
                    <input
                      className={style["input-checkbox"]}
                      type="checkbox"
                      checked={formData.newStock}
                      onChange={(e) => handleCheckbox(e, "newStock")}
                    />
                    <span style={{ paddingLeft: "10px" }}>New Stock</span>
                  </label>
                </div>
              </div>

              {/* <div className={style['tag-second-current']}>
              </div> */}

            </div>
          </div>

          <div className="tags">
            <SecondaryHeading
              title={"List On"}
              fontSize={"1.5rem"}
              fontWeight={500}
            />

<            div className={style['tag-first-container']}>

            
              <div className={style['tag-second-current']}>

  
                <div className={style['input-wrapper']}>
                  <label>
                    <input
                      className={style["input-checkbox"]}
                      type="checkbox"
                      checked={formData.isClassified}
                      onChange={(e) => handleCheckbox(e, "isClassified")}
                    />
                    <span style={{ paddingLeft: "10px" }}>Motorpedia</span>
                  </label>
                </div>

                <div className={style['input-wrapper']}>
                  <label  className={style['boxex']}>
                    <input
                      className={style["input-checkbox"]}
                      type="checkbox"
                      checked={formData.isWebsite}
                      onChange={(e) => handleCheckbox(e, "isWebsite")}
                    />
                    <span style={{ paddingLeft: "10px" }}>Website</span>
                  </label>
                </div>


              </div>
            </div>
          </div>

          <div className={style['marketing-data']}>
            <SecondaryHeading
              title={"Marketing Data"}
              fontSize={"1.5rem"}
              fontWeight={500}
            />
            <div className={style['first-container']}>
              <div className={style['left-side-current']}>
                <div className={style['input-wrapper']}>
                  <p className={style["label"]}>Car Description</p>
                  <textarea
                    placeholder="car Description"
                    name="carDescription"
                    onChange={handleChange}
                    value={formData?.carDescription}
                    className={style["textarea"]}
                    required={true}
                  />

                </div>

              </div>
              <div className={style['right-side-current']}>
                <div className={style["input-wrapper"]}>
                  <p className={style["label"]}>Meta Tag</p>
                  <textarea
                    placeholder="Meta Tag"
                    name="metaTag"
                    onChange={handleChange}
                    value={formData?.metaTag}
                    className={style["textarea"]}
                    required={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={style['action-wrapper']} style={{marginBottom:"100px"}}>
            <PrimaryButton
              name={"Submit"}
              fontSize={"1rem"}
              containerStyle={style["primary-button-container-style"]}
              onClick={addCar}
            />
          </div>
        </div>
      </div >
    </>
  );
}
